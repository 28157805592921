<template>
  <div>
    <el-row class="header_zone mt-10">
      <el-col :span="12">
        Хэрэглэгч удирдах хэсэг
      </el-col>
      <el-col :span="12" v-if="checkPermissionsPage('admin')">
        <el-button class="flr" @click="createUserDialogOpen()" type="success">Шинэ хэрэглэгч үүсгэх</el-button>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <el-card class="mt-10">
        <el-input placeholder="Утасны дугаар шүүх" style="width: 200px;" v-model="searchItems.searchPhone"/>
        <el-input class="ml-10" placeholder="Нэрээр шүүх" style="width: 200px; margin-right: 20px;" v-model="searchItems.searchName"/>
        <el-button @click="searchUsers(searchItems.searchPhone)">Хайх</el-button>
        <!-- Table with Static Columns -->
        <el-table style="width: 100%" border class="mt-10" :data="data">
          <!-- Checkbox Column -->
          <!-- Static Columns -->
          <el-table-column label="№" type="index"></el-table-column>
          <el-table-column label="Утасны дугаар" prop="phone1"></el-table-column>
          <el-table-column label="Нэр" prop="firstName"></el-table-column>
          <el-table-column label="Овог" prop="lastName"></el-table-column>
          <el-table-column label="Мэйл" prop="email"></el-table-column>

          <!-- Actions Column -->
          <el-table-column label="Үйлдэл" width="350" v-if="checkPermissionsPage('admin')">
            <template v-slot="scope">
              <el-button v-if="checkPermissionsPage('admin')" @click="createAddressDialogOpen(scope.row)" type="warning">Хаяг</el-button>
              <el-button v-if="checkPermissionsPage('admin')" @click="createUserDialogOpen(scope.row)">Засварлах</el-button>
              <el-button v-if="checkPermissionsPage('admin')" @click="deleteRow(scope.row)" type="danger">Устгах</el-button>
            </template>
          </el-table-column>
        </el-table>

        <!-- Pagination -->
        <el-pagination
          @current-change="handlePageChange"
          :current-page="currentPage"
          :page-size="pageSize"
          :total="totalRecords"
          layout="total, prev, pager, next, jumper"
          style="margin-top: 20px; text-align: right;" />
        </el-card>
      </el-col>

    </el-row>
    <CreateUserDialog :selectedUser="this.targetUser"/>
    <CreateAddressDialog :selectedUser="this.targetUser"/>
  </div>
</template>
<script>
import services from '@/services'
import { checkPermissions } from '@/helpers/custom'
import { ElMessage, ElMessageBox } from 'element-plus'
import CreateUserDialog from './createUserDialog.vue'
import CreateAddressDialog from './createAddressDialog.vue'
import { mapActions } from 'vuex'

export default {
  name: 'UserListPage',
  components: {
    CreateUserDialog,
    CreateAddressDialog
  },
  created () {
    this.loadData()
  },
  data () {
    return {
      createAddressDialog: false,
      currentPage: 1,
      pageSize: 10,
      data: [],
      // Example dynamic columns
      columns: [],
      targetUser: {},
      searchItems: {
        searchPhone: null,
        searchName: null
      },
      totalRecords: null
    }
  },
  methods: {
    ...mapActions(['setCreateUserDialog', 'setCreateAddressDialog']),
    loadColumns () {
      const columns = [{
        label: 'Нэр',
        prop: 'firstName',
        type: 'text',
        required: true
      },
      {
        label: 'Овог',
        prop: 'lastName',
        type: 'text',
        required: true
      },
      {
        label: 'Утасны дугаар',
        prop: 'phone1',
        type: 'text',
        required: true
      },
      {
        label: 'Мэйл',
        prop: 'email',
        type: 'text',
        required: false
      }
      ]
      this.columns = columns // Багануудыг хадгалах
    },
    async loadData () {
      try {
        const response = await services.get('users', {
          params: {
            page: this.currentPage, // Одоогийн хуудас
            limit: this.pageSize // Хуудасны хэмжээ
          }
        })
        if (response) {
          this.data = response.data
          this.totalRecords = response.total// Pagination тоо
          this.totalPages = response.totalPages
        } else {
          // Хариулт дотор data байхгүй бол алдаа харуулна
          ElMessage.error('Өгөгдөл олдсонгүй!')
        }
      } catch (error) {
        console.error('Error fetching data: ', error)
        // Алдааг консолоор илүү дэлгэрэнгүй харуулах
        ElMessage.error(`Өгөгдөл ачаалахад алдаа гарлаа: ${error.message}`)
      }
    },
    checkPermissionsPage (data) {
      return checkPermissions(data)
    },
    handlePageChange (page) {
      this.currentPage = page
      this.loadData()
    },
    editRow (row) {
      // Edit functionality here
      console.log('Edit Row: ', row)
      // Open dialog for editing...
    },
    createUserDialogOpen (data) {
      this.targetUser = data
      this.setCreateUserDialog(true)
    },
    createAddressDialogOpen (data) {
      this.targetUser = data
      this.setCreateAddressDialog(true)
    },
    async deleteRow (row) {
      try {
        await ElMessageBox.confirm('Та энэ бичлэгийг устгахдаа итгэлтэй байна уу?', 'Баталгаажуулалт', {
          confirmButtonText: 'Тийм',
          cancelButtonText: 'Үгүй',
          type: 'warning'
        })

        // API устгах хүсэлт явуулах
        await services.delete('users', row.id)

        // Success message
        ElMessage.success('Амжилттай устгагдлаа!')
        this.loadData()
        // Remove row from data after deletion
        const index = this.data.indexOf(row)
        if (index > -1) {
          this.data.splice(index, 1)
        }
      } catch (error) {
        if (error !== 'cancel') {
          ElMessage.error('Устгахад алдаа гарлаа!')
        }
      }
    },
    searchUsers (data) {
      try {
        const payload = data
        const response = services.post('searchUser', payload, {
          params: {
            page: this.currentPage,
            limit: this.pageSize
          }
        })
        if (response) {
          this.data = response.data
          this.totalRecords = response.total// Pagination тоо
          this.totalPages = response.totalPages
        } else {
          ElMessage.error('Өгөгдөл олдсонгүй!')
        }
      } catch (error) {
        console.error('Error fetching data: ', error)
        // Алдааг консолоор илүү дэлгэрэнгүй харуулах
        ElMessage.error(`Өгөгдөл ачаалахад алдаа гарлаа: ${error.message}`)
      }
    }
  }
}

</script>
<style lang="scss">
  .header_zone {
    background-color: white;
    padding: 20px;
  }

  .flr {
    float: right;
  }

  .el-table {
    margin-bottom: 20px;
  }

</style>
