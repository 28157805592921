<template>
    <el-dialog :title="formData.id ? 'Засварлах' : 'Track code үүсгэх'" v-model="createTrackCodeDialog" width="50%"
      @close="handleClose">
      <div>
        <el-form :model="formData" :rules="rules" ref="formRef" label-width="120px">
          <el-form-item label="Track code" prop="track_code">
            <el-input v-model="formData.track_code" placeholder="Track code оруулна уу"></el-input>
          </el-form-item>
          <el-form-item label="Утасны дугаар" prop="phone_number">
            <el-input v-model="formData.phone_number" placeholder="Утасны дугаар оруулна уу"></el-input>
          </el-form-item>
          <el-form-item label="Хүлээн авах салбар">
            <el-select v-model="formData.user_select_branch" placeholder="Хүлээн авах салбар сонгоно уу">
              <el-option v-for="item,index in branchesList" :key="index" :label="item.branch_name" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Үнэ" prop="price">
            <el-input v-model="formData.price" placeholder="Үнэ оруулна уу"></el-input>
          </el-form-item>
          <el-form-item label="Барааны хэмжээ" prop="product_size">
            <el-select v-model="formData.product_size" placeholder="Барааны хэмжээ оруулна уу">
              <el-option v-for="item,value in optionProductSize" :key="value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Барааны төрөл" prop="product_type">
            <el-select v-model="formData.product_type" placeholder="Барааны төрөл оруулна уу">
              <el-option v-for="item,value in optionProductType" :key="value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Жин" prop="jin">
            <el-input v-model="formData.jin" placeholder="Жин оруулна уу" type="number"></el-input>
          </el-form-item>
          <el-form-item label="Урт" prop="urt">
            <el-input v-model="formData.urt" placeholder="Урт оруулна уу" type="number"></el-input>
          </el-form-item>
          <el-form-item label="Өндөр" prop="undur">
            <el-input v-model="formData.undur" placeholder="Өндөр оруулна уу" type="number"></el-input>
          </el-form-item>
          <el-form-item label="Өргөн" prop="urgun">
            <el-input v-model="formData.urgun" placeholder="Өргөн оруулна уу" type="number"></el-input>
          </el-form-item>
          <el-form-item label="Эзэлхүүн" prop="ezelhuun">
            <el-input v-model="formData.ezelhuun" placeholder="Эзэлхүүн оруулна уу" type="number"></el-input>
          </el-form-item>
          <el-form-item label="Ханш" prop="exchange">
            <el-input v-model="formData.exchange" placeholder="Ханш оруулна уу" type="number"></el-input>
          </el-form-item>
          <el-form-item label="Тээврийн үнэ" prop="teevriin_une">
            <el-input v-model="formData.teevriin_une" placeholder="Тээврийн үнэ оруулна уу" type="number"></el-input>
          </el-form-item>
          <el-form-item label="Тайлбар" prop="description">
            <el-input v-model="formData.description" placeholder="Тайлбар оруулна уу" type="textarea"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm">Хадгалах</el-button>
            <!-- <el-button @click="resetForm">Цэвэрлэх</el-button> -->
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
</template>

<script>
import services from '../services'
import {
  ElMessage,
  ElForm,
  ElFormItem,
  ElInput,
  ElButton
} from 'element-plus'

import {
  mapActions,
  mapState,
  mapGetters
} from 'vuex'

export default {
  name: 'CreateEditForm',

  components: {
    ElForm,
    ElFormItem,
    ElInput,
    ElButton
  },
  computed: {
    ...mapState(['createTrackCodeDialog']),
    ...mapGetters(['user'])
  },
  props: {
    selectedTrackCode: {
      type: Object,
      default: () => ({})
    }

  },
  data () {
    return {
      formData: {
        track_code: '',
        phone_number: '',
        user_select_branch: null,
        product_size: '',
        product_type: '',
        jin: '',
        urt: '',
        undur: '',
        urgun: '',
        exchange: '',
        ezelhuun: '',
        teevriin_une: '',
        description: '',
        created_user_id: null
      },
      branchesList: [],
      rules: {
        track_code: [{
          required: true,
          message: 'Track code оруулна уу',
          trigger: 'blur'
        }]
      },
      optionProductSize: [
        {
          label: 'Том',
          value: 'Том'
        },
        {
          label: 'Жижиг',
          value: 'Жижиг'
        }
      ],
      optionProductType: [
        {
          label: 'Ууттай',
          value: 'Ууттай'
        },
        {
          label: 'Хайрцагтай',
          value: 'Хайрцагтай'
        }
      ]
    }
  },
  watch: {
    selectedTrackCode: {
      immediate: true,
      handler (newValue) {
        this.formData = {
          ...newValue
        }
        this.setDynamicImgUrl(this.selectedTrackCode.image_path)
      }
    },
    dynamic_img_url () {
      this.formData.image_path = this.dynamic_img_url
    }
  },
  created () {
    this.getBranch()
  },
  methods: {
    ...mapActions(['setCreateTrackCodeDialog', 'setDynamicImgUrl']),
    handleClose () {
      this.formData = {}
      this.setCreateTrackCodeDialog(false)
    },
    submitForm () {
      this.$refs.formRef.validate((valid) => {
        if (valid) {
          let response
          try {
            this.formData.created_user_id = this.user.id
            response = services.update(this.$route.name, this.formData)
            if (response) {
              this.handleClose()
              this.$router.push('/trackCodes')
            }
          } catch (error) {
            console.error('API Request Error:', error.message)
            this.$message.error(error.message)
          }
        } else {
          console.log('Алдаа гарлаа, шаардлагатай талбаруудыг бөглөнө үү')
          return false
        }
      })
    },
    async getBranch () {
      try {
        const response = await services.get('branches')
        if (response) {
          this.branchesList = response.data
        } else {
          ElMessage.error('Өгөгдөл олдсонгүй!')
        }
      } catch (error) {
        console.error('Erroe fetching data: ', error)
        ElMessage.error(`Өгөгдөл ачааллахад алдаа гарлаа: ${error.message} `)
      }
    }
  }
}

</script>

  <style scoped>
    .el-form {
      max-width: 600px;
      margin: 0 auto;
    }

  </style>
