<template>
  <el-dialog title="Хаягийн мэдээлэл" v-model="createAddressDialog" width="70%"
    @close="handleClose">
    <div>
    <div v-if="selectedUser.Address">
      <el-row>
        <el-col>
            <el-tag v-for="item,index in selectedUser.Address" :key="index" style="cursor: pointer; margin: 10px;" class="p-10" size="large" @click="clickAddress(item)">{{ item.nick_name }}</el-tag>
        </el-col>
        <el-col>
          <el-form>
        <el-form-item>
          <p>Nick name</p>
          <el-input v-model="formData.nick_name" placeholder="Гэр, ажил гэх мэт"></el-input>
        </el-form-item>
        <el-form-item>
          <p>Дэлгэрэнгүй хаяг</p>
          <el-input v-model="formData.detail_address" placeholder="Дэлгэрэнгүй хаяг оруулна уу" type="textarea"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="handleClose">Хаах</el-button>
            <el-button @click="submitForm" type="primary">Хадгалах</el-button>
        </el-form-item>
      </el-form>
        </el-col>
      </el-row>
    </div>
    {{ formData.Address }}
    </div>
  </el-dialog>
</template>

<script>
import services from '../services'
import {
  mapState,
  mapActions
} from 'vuex'

export default {
  name: 'createAddressDialog',
  computed: {
    ...mapState(['createAddressDialog'])
  },
  props: {
    selectedUser: {
      type: Object,
      default: () => ({})
    }
  },
  created () {

  },
  data () {
    return {
      formData: {
        nick_name: null,
        detail_address: null,
        id: null
      }
    }
  },
  methods: {
    ...mapActions(['setCreateAddressDialog']),
    handleClose () {
      this.setCreateAddressDialog(false)
    },
    submitForm () {
      const payload = { ...this.formData, user_id: this.selectedUser.id }
      try {
        this.$confirm('Хэрэглэгчийн хаяг бүртгэх үү?', 'Анхааруулга', {
          confirmButtonText: 'Тийм',
          cancelButtonText: 'Үгүй',
          type: 'warning'
        }).then(() => {
          const call = this.formData.id
            ? services.update('address', payload)
            : services.create('address', payload)

          call
            .then((response) => {
              if (response.type === 'unsuccess') {
                this.$notify({
                  title: 'Алдаа',
                  message: response.message,
                  type: 'error',
                  position: 'top-left'
                })
              } else {
                this.$notify({
                  title: 'Амжилттай',
                  message: 'Мэдээлэл амжилттай хадгалагдлаа.',
                  type: 'success',
                  position: 'top-left'
                })
                this.handleClose()
                location.reload()
              }
            })
        })
      } catch (error) {
        console.error('API Request Error:', error.message)
        this.$message.error(error.message)
      }
    },
    clickAddress (item) {
      this.formData = {
        nick_name: item.nick_name,
        detail_address: item.detail_address,
        id: item.id || null
      }
    }
  }
}
</script>

  <style scoped>
    .dialog-footer {
      text-align: right;
    }

  </style>
