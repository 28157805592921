import { createRouter, createWebHistory } from 'vue-router'
import Login from '../views/Login.vue'
import Register from '../views/Register.vue'
import mainLayout from '@/views/layout/mainLayout.vue'
import Dashboard from '@/views/Dashboard.vue'
import UserList from '@/views/UserList.vue'
import BannerList from '@/views/BannerList.vue'
import FAQs from '@/views/FAQs.vue'
import store from '@/store'
import TrackCode from '@/views/TrackCode.vue'
import Worker from '@/views/WorkerView.vue'

const routes = [{
  path: '/',
  component: mainLayout,
  redirect: '/configs',
  meta: {
    requiresAuth: true
  },
  children: [{
    path: '/configs',
    name: 'configs',
    component: Dashboard,
    meta: {
      requiresAuth: true
    }
  }, {
    path: '/users',
    name: 'users',
    component: UserList,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/banners',
    name: 'banners',
    component: BannerList,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/faqs',
    name: 'faqs',
    component: FAQs,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/trackCodes',
    name: 'trackCodes',
    component: TrackCode,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/worker',
    name: 'worker',
    component: Worker,
    meta: {
      requiresAuth: true
    }
  }
  ]
}, {
  path: '/login',
  name: 'Login',
  component: Login,
  meta: {
    requiresAuth: false
  }
},
{
  path: '/register',
  name: 'Register',
  component: Register,
  meta: {
    requiresAuth: false
  }
},
{
  path: '/login',
  name: 'AuthError',
  component: Login,
  meta: {
    requiresAuth: false
  }
}
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.meta.requiresAuth // Маршрут authentication шаардах эсэхийг шалгах
  const token = store.state.token // Хэрэглэгчийн authentication токен
  const user = store.state.user // Хэрэглэгчийн мэдээлэл

  if (requiresAuth && !token) {
    // Authentication шаардлагатай боловч токен байхгүй үед Login руу шилжүүлэх
    next({ name: 'Login' })
  } else if (!requiresAuth) {
    // Authentication шаардлагагүй бол шууд үргэлжлүүлэх
    next()
  } else {
    // Authentication шаардлагатай бөгөөд токен байгаа тохиолдолд хэрэглэгчийн мэдээллийг шалгах
    if (user) {
      if (user.lastName) {
        // Хэрэглэгчийн lastName байвал үргэлжлүүлэх
        next()
      } else {
        // lastName байхгүй бол AuthError руу шилжүүлэх
        next({ name: 'AuthError' })
      }
    } else {
      // Хэрэглэгчийн мэдээлэл байхгүй бол үргэлжлүүлэх
      next()
    }
  }
})

export default router
