<template>
    <el-dialog :title="formData.id ? 'Засварлах' : 'Шинэ түгээмэл асуулт үүсгэх'" v-model="createFAQsDialog" width="50%"
      @close="handleClose">
      <div>
        <el-form :model="formData" :rules="rules" ref="formRef" label-width="120px">
          <el-form-item label="Асуулт" prop="answer">
            <el-input v-model="formData.answer" placeholder="Түгээмэл асуулт оруулна уу"></el-input>
          </el-form-item>
          <el-form-item label="Хариулт" prop="question">
            <el-input type="textarea" v-model="formData.question" placeholder="Дээрх асуултын хариулт оруулна уу"></el-input>
          </el-form-item>
          <el-form-item label="Идэвхтэй эсэх" prop="is_active">
            <el-switch v-model="formData.is_active"></el-switch>
          </el-form-item>
          <el-form-item>
            <el-button @click="handleClose">Хаах</el-button>
            <el-button type="primary" @click="submitForm">Хадгалах</el-button>
            <!-- <el-button @click="resetForm">Цэвэрлэх</el-button> -->
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </template>

<script>
import services from '../services'

import {
  ElForm,
  ElFormItem,
  ElInput,
  ElButton
} from 'element-plus'
import {
  mapActions,
  mapState
} from 'vuex'

export default {
  name: 'FAQsDialog',

  components: {
    ElForm,
    ElFormItem,
    ElInput,
    ElButton
  },
  computed: {
    ...mapState(['createFAQsDialog'])
  },
  props: {
    selectedFAQs: {
      type: Object,
      default: () => ({})
    }

  },
  created () {
    // this.checkEdit()
  },
  data () {
    return {
      formData: this.selectedFAQs.id
        ? this.selectedFAQs
        : {
            answer: '',
            question: '',
            is_active: true
          },
      // formData: {
      //   answer: '',
      //   question: '',
      //   is_active: true
      // },
      rules: {
        answer: [{
          required: true,
          message: 'Түгээмэл асуулт оруулна уу',
          trigger: 'blur'
        }],
        question: [{
          required: true,
          message: 'Дээрх асуултын хариулт оруулна уу',
          trigger: 'blur'
        }]
      }
    }
  },
  watch: {
    selectedFAQs: {
      immediate: true, // Хэрэглэгчийн өгөгдөл анх ороход ажиллана
      handler (newValue) {
        this.formData = {
          ...newValue
        }
      }
    }
  },
  methods: {
    ...mapActions(['setCreateFAQsDialog']),
    handleClose () {
      this.formData = {}
      this.setCreateFAQsDialog(false)
    },
    submitForm () {
      this.$refs.formRef.validate((valid) => {
        if (valid) {
          let response
          try {
            if (this.formData.id) {
              response = services.update(this.$route.name, this.formData)
            } else {
              response = services.create(this.$route.name, this.formData)
            }
            if (response) {
              this.handleClose()
              this.$router.push('/faqs')
              // location.reload()
            }
          } catch (error) {
            console.error('API Request Error:', error.message)
            this.$message.error(error.message)
          }
        } else {
          console.log('Алдаа гарлаа, шаардлагатай талбаруудыг бөглөнө үү')
          return false
        }
      })
    }
  }
}

</script>

  <style scoped>
    .el-form {
      max-width: 600px;
      margin: 0 auto;
    }

  </style>
