<template>
  <div>
    <el-row :gutter="0">
      <el-col :span="3">
        <SidebarComponent></SidebarComponent>
      </el-col>
      <el-col :span="21" class="bg_color p-10">
        <router-view />
      </el-col>
    </el-row>
    <loader-item :isLoader='this.$root.fullscreenLoading'></loader-item>
  </div>
</template>
<script>
import LoaderItem from '@/components/loader/loader-item.vue'
import SidebarComponent from '@/components/SidebarComponent.vue'

export default {
  name: 'MainLayout',
  components: {
    LoaderItem,
    SidebarComponent
  },
  data () {
    return {
      containerCollapsed: 'toggled',
      tabledata: [],
      user: JSON.parse(localStorage.getItem('user'))

    }
  },
  created () {
  },
  methods: {
    showMenu () {
      this.$root.showMenuDrawerStatus = true
    },
    logout () {
      localStorage.clear()
      this.$router.push({
        path: '/login'
      })
    }
  }
}

</script>
<style scoped>
.el-header {
  background-color: #ffffff;
  color: #333;
  line-height: 60px;
  padding: 0px;
  min-height: 68px;
}

.el-aside {
  color: #333;
}

.item {
  margin-top: 12px;
  margin-right: 20px;
}
</style>
