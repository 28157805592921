import { createStore } from 'vuex'

export default createStore({
  state: {
    dialogVisible: false, // Dialog-ийн харагдах байдал
    formData: {}, // Form-ийн өгөгдөл
    columns: [], // Columns for the dynamic form
    isEditing: false, // Боловсруулж байгаа эсэх
    token: localStorage.getItem('token') ? localStorage.getItem('token') : null, // Хэрэглэгчийн token
    user: null,
    createUserDialog: false,
    createBannerDialog: false,
    cdn: 'https://daagiigaadskycargo.s3.ap-southeast-1.amazonaws.com',
    dynamic_img_url: '',
    createFAQsDialog: false,
    createTrackCodeDialog: false,
    createAddressDialog: false,
    createConfigDialog: false,
    seeTrackCodeProgressDialog: false,
    role: null,
    permissions: localStorage.getItem('permissions') ? localStorage.getItem('permissions') : [],
    createWorkerDialog: false,
    createConfigDetailDialog: false,
    changeDataDialog: false
  },
  mutations: {
    setToken (state, token) {
      state.token = token
      localStorage.setItem('token', token) // Хадгалах
    },
    setUser (state, user) {
      state.user = user
      localStorage.setItem('user', JSON.stringify(user)) // Хадгалах
    },
    setPermissionsCommit (state, permissions) {
      state.permissions = permissions
      localStorage.setItem('permissions', JSON.stringify(permissions)) // Хадгалах
    },
    setRole (state, role) {
      state.role = role
      localStorage.setItem('role', JSON.stringify(role)) // Хадгалах
    },
    removeToken (state) {
      state.token = null
      localStorage.removeItem('token') // Устгах
    },
    removeUser (state) {
      state.user = null
      localStorage.removeItem('user') // Устгах
    },
    SET_CREATE_USER_DIALOG (state, status) {
      state.createUserDialog = status
    },
    SET_CREATE_BANNER_DIALOG (state, status) {
      state.createBannerDialog = status
    },
    setDialogVisible (state, status) {
      state.dialogVisible = status
    },
    // Dialog visibility тохируулах
    SET_DIALOG_VISIBLE (state, visibility) {
      state.dialogVisible = visibility
      state.formData = {}
    },
    // Form-ийн өгөгдөл тохируулах
    SET_FORM_DATA (state, data) {
      state.formData = data
    },
    // Editing state тохируулах
    SET_IS_EDITING (state, editing) {
      state.isEditing = editing
    },
    SET_DYNAMIC_IMG_URL (state, url) {
      state.dynamic_img_url = url
    },
    // Columns тохируулах
    SET_COLUMNS (state, columns) {
      state.columns = columns
      // Initialize formData with default values based on columns
      const formData = {}
      columns.forEach(column => {
        if (column.type === 'select') {
          formData[column.prop] = column.options ? column.options[0].value : ''
        } else {
          formData[column.prop] = ''
        }
      })
      state.formData = formData // Set initial formData based on columns
    },
    SET_CREATE_FAQS_DIALOG (state, status) {
      state.createFAQsDialog = status
    },
    SET_CREATE_TRACK_CODE_DIALOG (state, status) {
      state.createTrackCodeDialog = status
    },
    SET_CREATE_ADDRESS_DIALOG (state, status) {
      state.createAddressDialog = status
    },
    SET_CREATE_CONFIG_DIALOG (state, status) {
      state.createConfigDialog = status
    },
    SET_CHANGE_DATA_DIALOG (state, status) {
      state.changeDataDialog = status
    },
    SET_CHANGE_DOOD_JIN_TOOTSOH_DUN_DIALOG (state, status) {
      state.changeDoodJinTootsohDunDialog = status
    },
    SET_CREATE_WORKER_DIALOG (state, status) {
      state.createWorkerDialog = status
    },
    SET_CONFIG_DETAIL_DIALOG (state, status) {
      state.createConfigDetailDialog = status
    },
    SET_SEE_TRACK_CODE_PROGRESS_DIALOG (state, status) {
      state.seeTrackCodeProgressDialog = status
    }
  },
  actions: {
    setToken ({ commit }, token) {
      commit('setToken', token) // Token хадгалах
    },
    setUser ({ commit }, user) {
      commit('setUser', user) // Хэрэглэгчийн мэдээлэл хадгалах
    },
    setRole ({ commit }, role) {
      commit('setRole', role) // Хэрэглэгчийн мэдээлэл хадгалах
    },
    setPermissions ({ commit }, role) {
      commit('setPermissionsCommit', role) // Хэрэглэгчийн мэдээлэл хадгалах
    },
    getToken ({ state }) {
      return state.token || localStorage.getItem('token') // Token авах
    },
    getUser ({ state }) {
      return state.user || JSON.parse(localStorage.getItem('user')) // Хэрэглэгчийн мэдээлэл авах
    },
    getRole ({ state }) {
      return state.role || JSON.parse(localStorage.getItem('role')) // Хэрэглэгчийн мэдээлэл авах
    },
    // Dialog-ийг нээх
    openDialog ({ commit }) {
      commit('SET_DIALOG_VISIBLE', true)
    },
    // Dialog-ийг хаах
    closeDialog ({ commit }) {
      commit('SET_DIALOG_VISIBLE', false)
    },
    // Form-ийн өгөгдөл тохируулах
    setFormData ({ commit }, data) {
      commit('SET_FORM_DATA', data)
    },
    setDialogVisible ({ commit }, status) {
      commit('setDialogVisible', status)
    },
    // Editing тохируулах
    setIsEditing ({ commit }, editing) {
      commit('SET_IS_EDITING', editing)
    },
    // Columns тохируулах
    setColumns ({ commit }, columns) {
      commit('SET_COLUMNS', columns)
    },
    setCreateUserDialog ({ commit }, status) {
      commit('SET_CREATE_USER_DIALOG', status)
    },
    setCreateBannerDialog ({ commit }, status) {
      commit('SET_CREATE_BANNER_DIALOG', status)
    },
    setDynamicImgUrl ({ commit }, url) {
      commit('SET_DYNAMIC_IMG_URL', url)
    },
    setCreateFAQsDialog ({ commit }, status) {
      commit('SET_CREATE_FAQS_DIALOG', status)
    },
    setCreateTrackCodeDialog ({ commit }, status) {
      commit('SET_CREATE_TRACK_CODE_DIALOG', status)
    },
    setCreateAddressDialog ({ commit }, status) {
      commit('SET_CREATE_ADDRESS_DIALOG', status)
    },
    setCreateConfigDialog ({ commit }, status) {
      commit('SET_CREATE_CONFIG_DIALOG', status)
    },
    setChangeDataDialog ({ commit }, status) {
      commit('SET_CHANGE_DATA_DIALOG', status)
    },
    setCreateConfigDetailDialog ({ commit }, status) {
      commit('SET_CONFIG_DETAIL_DIALOG', status)
    },
    setSeeTrackCodeProgressDialog ({ commit }, status) {
      commit('SET_SEE_TRACK_CODE_PROGRESS_DIALOG', status)
    },
    setCreateWorkerDialog ({ commit }, status) {
      commit('SET_CREATE_WORKER_DIALOG', status)
    }
  },
  getters: {
    token: (state) => state.token || localStorage.getItem('token'),
    user: (state) => state.user || JSON.parse(localStorage.getItem('user')),
    role: (state) => state.role || JSON.parse(localStorage.getItem('role')),
    dialogVisible: (state) => state.dialogVisible,
    formData: (state) => state.formData,
    columns: (state) => state.columns,
    isEditing: (state) => state.isEditing,
    cdnGetter: (state) => state.cdn,
    imgUrlGetter: (state) => state.dynamic_img_url
  }
})
