<template>
  <div>
    <el-row class="header_zone mt-10">
      <el-col :span="12">
          Track code удирдах хэсэг
      </el-col><br><br>
      <el-col>
        <div class="search-container">
        <el-input v-model="searchQuery" placeholder="Утасны дугаар эсвэл track code ..." clearable /><br><br>
        <el-button type="primary" @click="searchItem(searchQuery)">Хайх</el-button>
        <el-button @click="clearSearch()">Цэвэрлэх</el-button>
      </div>
      </el-col>
      <!-- <el-col :span="24" v-if="checkPermissionsPage('admin')">
          <el-button @click="createTrackCodeDialogOpen()" class="flr" type="success">Track code нэмэх</el-button>
      </el-col> -->
    </el-row>
    <el-row>
      <el-col>
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="User" name="first">User</el-tab-pane>
          <el-tab-pane label="Config" name="second">Config</el-tab-pane>
          <el-tab-pane label="Role" name="third">Role</el-tab-pane>
          <el-tab-pane label="Task" name="fourth">Task</el-tab-pane>
        </el-tabs>
      </el-col>
        <el-col :span="24">
            <el-card>
              <el-table :data="data" border class="mt-10">
                <el-table-column label="№" prop="id" fixed="left"></el-table-column>
                <el-table-column label="Track code" prop="track_code" fixed="left"></el-table-column>
                <el-table-column v-if="checkPermissionsPage('admin')" label="Төлөв" fixed="left" width="170px"
                :filters="[
                  { text: 'Эрээн дах агуулахаас гарсан', value: 'Эрээн дах агуулахаас гарсан' },
                  { text: 'Монгол дах салбар хүлээн авсан', value: 'Монгол дах салбар хүлээн авсан' },
                  { text: 'Хэрэглэгч бүртгэсэн', value: 'Хэрэглэгч бүртгэсэн' },
                  { text: 'Хэрэглэгч хүргэлтээр авна', value: 'Хэрэглэгч хүргэлтээр авна' },
                  { text: 'Хэрэглэгч хүлээн авсан', value: 'Хэрэглэгч хүлээн авсан' }
                ]" :filter-method="filterHandler">
                    <template #default="scope">
                      <el-tag @click="seeTrackCodeProgressDialogOpen(scope.row)" v-if="scope.row.Progress && scope.row.Progress.length > 0 && scope.row.Progress[0].progress_code === 100" class="cursor">Хэрэглэгч бүртгэсэн </el-tag>
                      <el-tag @click="seeTrackCodeProgressDialogOpen(scope.row)" v-if="scope.row.Progress && scope.row.Progress.length  > 0 && scope.row.Progress[0].progress_code === 110" class="cursor">Эрээн дах агуулахаас гарсан</el-tag>
                      <el-tag @click="seeTrackCodeProgressDialogOpen(scope.row)" v-if="scope.row.Progress && scope.row.Progress.length  > 0 && scope.row.Progress[0].progress_code === 120" class="cursor">Монгол дах салбар хүлээн авсан</el-tag>
                      <el-tag @click="seeTrackCodeProgressDialogOpen(scope.row)" v-if="scope.row.Progress && scope.row.Progress.length  > 0 && scope.row.Progress[0].progress_code === 200" class="cursor">Хэрэглэгч хүлээн авсан</el-tag>
                      <el-tag @click="seeTrackCodeProgressDialogOpen(scope.row)" v-if="scope.row.Progress && scope.row.Progress.length  > 0 && scope.row.Progress[0].progress_code === 130" class="cursor">Хэрэглэгч хүргэлтээр авна</el-tag>
                      <el-tag @click="seeTrackCodeProgressDialogOpen(scope.row)" v-if="scope.row.Progress && scope.row.Progress.length  > 0 && scope.row.Progress[0].progress_code === 140" class="cursor">Хэрэглэгч салбараас очиж авна</el-tag>
                      <el-tag @click="seeTrackCodeProgressDialogOpen(scope.row)" v-if="scope.row.Progress && scope.row.Progress.length  > 0 && scope.row.Progress[0].progress_code === 150" class="cursor">Хэрэглэгч салбар дээр хадгалуулна</el-tag>
                      <el-tag @click="seeTrackCodeProgressDialogOpen(scope.row)" v-if="scope.row.Progress && scope.row.Progress.length  > 0 && scope.row.Progress[0].progress_code === 160" class="cursor">Хүргэлтийн ажилтанд хуваарилсан</el-tag>
                      <el-tag @click="seeTrackCodeProgressDialogOpen(scope.row)" v-if="scope.row.Progress && scope.row.Progress.length  > 0 && scope.row.Progress[0].progress_code === 170" class="cursor">Ачаа салбар дээр үлдсэн</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="Утасны дугаар" prop="phone_number"></el-table-column>
                <el-table-column label="Үүсгэсэн огноо">
                    <template v-slot:default="scope">
                      <el-tag type="warning" effect="dark">{{ timeSince(scope.row.createdAt) }}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="Үнэ">
                  <template v-slot="scope">
                    {{ $formatCurrency(scope.row.price) }}
                  </template>
                </el-table-column>
                <el-table-column label="Төлбөрийн хэлбэр">
                  <template v-slot="scope">
                    {{ paidTypeLabel(scope.row.paid_type) }}
                  </template>
                </el-table-column>
                <!-- <el-table-column label="Үйлдэл" v-if="checkPermissionsPage('admin')" width="400px">
                  <template v-slot="scope">
                    <el-button v-if="checkPermissionsPage('admin')" @click="chooseDriver(scope.row)">Жолооч хувиарлах</el-button>
                    <el-button v-if="checkPermissionsPage('admin')" @click="createTrackCodeDialogOpen(scope.row)" type="warning">Засварлах</el-button>
                    <el-button v-if="checkPermissionsPage('admin')" @click="deleteRow(scope.row)" type="danger">Устгах</el-button>
                  </template>
                </el-table-column> -->
            </el-table>
            <!-- Pagination -->
            <el-pagination
              @current-change="handlePageChange"
              :current-page="currentPage"
              :page-size="pageSize"
              :total="totalRecords"
              layout="total, prev, pager, next, jumper"
              style="margin-top: 20px; text-align: right;" />
            </el-card>
        </el-col>
    </el-row>
    <CreateTrackCodeDialog :selectedTrackCode="this.targetTrackCode" />
    <SeeTrackCodeProgressDialog :selectedTrackCode="this.targetTrackCode" />
  </div>
</template>

<script>
import services from '@/services'
import CreateTrackCodeDialog from './createTrackCodeDialog.vue'
import SeeTrackCodeProgressDialog from './seeTrackCodeProgressDialog.vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { mapActions, mapGetters } from 'vuex'
import { checkPermissions } from '@/helpers/custom'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Asia/Ulaanbaatar')
export default {
  name: 'TrackCodeView',
  components: {
    CreateTrackCodeDialog,
    SeeTrackCodeProgressDialog
  },
  data () {
    return {
      currentPage: 1,
      pageSize: 50,
      data: [],
      columns: [],
      searchQuery: null,
      targetTrackCode: {},
      totalRecords: null
    }
  },
  created () {
    this.loadData()
  },
  computed: {
    ...mapGetters([
      'cdnGetter'
    ])
  },
  methods: {
    timeSince (datestring) {
      const date1 = dayjs(datestring)
      const date2 = dayjs().tz('Asia/Ulaanbaatar')
      let seconds = date2.diff(date1)
      seconds = seconds / 1000

      let interval = Math.floor(seconds / 31536000)

      if (interval > 1) {
        return interval + ' жил'
      }
      interval = Math.floor(seconds / 2592000)
      if (interval > 1) {
        return interval + ' сар'
      }
      interval = Math.floor(seconds / 86400)
      if (interval > 1) {
        return interval + ' өдөр'
      }
      interval = Math.floor(seconds / 3600)
      if (interval > 1) {
        return interval + ' цаг'
      }
      interval = Math.floor(seconds / 60)
      if (interval > 1) {
        return interval + ' минут'
      }
      return Math.floor(seconds) + ' секунд'
    },
    ...mapActions(['setCreateTrackCodeDialog', 'setSeeTrackCodeProgressDialog']),
    paidTypeLabel (data) {
      switch (data) {
        case 'cash':
          return 'Бэлэн төлөлт'
        case 'transaction':
          return 'Шүлжүүлэг'
        case 'card':
          return 'Карт'
      }
    },
    async loadData () {
      try {
        const response = await services.get('trackCodesPagination', {
          params: {
            page: this.currentPage,
            limit: this.pageSize
          }
        })
        if (response) {
          this.data = response.data
          this.totalRecords = response.total
          this.totalPages = response.totalPages
        } else {
          ElMessage.error('Өгөгдөл олдсонгүй!')
        }
      } catch (error) {
        console.error('Erroe fetching data: ', error)
        ElMessage.error(`Өгөгдөл ачааллахад алдаа гарлаа: ${error.message} `)
      }
    },
    handlePageChange (page) {
      this.currentPage = page
      this.loadData()
    },
    checkPermissionsPage (data) {
      return checkPermissions(data)
    },
    createTrackCodeDialogOpen (data) {
      this.targetTrackCode = data
      this.setCreateTrackCodeDialog(true)
    },
    seeTrackCodeProgressDialogOpen (data) {
      console.log(data)
      this.targetTrackCode = data
      this.setSeeTrackCodeProgressDialog(true)
    },
    async deleteRow (row) {
      try {
        await ElMessageBox.confirm('Та энэ бичлэгийг устгахдаа итгэлтэй байна уу?', 'Баталгаажуулалт', {
          confirmButtonText: 'Тийм',
          cancelButtonText: 'Үгүй',
          type: 'warning'
        })

        // API устгах хүсэлт явуулах
        await services.delete('trackCodes', row.id)
        this.loadData()
        // Success message
        ElMessage.success('Амжилттай устгагдлаа!')
        // Remove row from data after deletion
        const index = this.data.indexOf(row)
        if (index > -1) {
          this.data.splice(index, 1)
        }
      } catch (error) {
        if (error !== 'cancel') {
          ElMessage.error('Устгахад алдаа гарлаа!')
        }
      }
    },
    async searchItem (data) {
      if (!data) {
        this.$alert('Уучлаарай. Хайх утга оруулна уу.', 'Хоосон', {
          confirmButtonText: 'Ойлголоо'
        })
        return
      }
      try {
        const trackCode = data
        const payload = trackCode
        const response = await services.get(`trackCodesPaginationSearch/${payload}`)
        console.log(payload)
        if (response.type === 'success') {
          this.data = response.data
        } else {
          this.$message.error('Өгөгдөл олдсонгүй!')
        }
      } catch (error) {
        console.error('Алдаа гарлаа:', error)
        this.$message.error('Өгөгдлийг татахад алдаа гарлаа!')
      }
    },
    clearSearch () {
      this.searchQuery = null
      this.loadData()
    },
    filterHandler (value, row, column) {
      const property = column.property
      return row[property] === value
    }
  }
}
</script>

<style lang="scss">
    .header_zone {
        background-color: white;
        padding: 20px;
    }
    .flr {
        float: right;
    }
    .el-table {
        margin-bottom: 20px;
    }
    .cursor{
      cursor: pointer;
    }
</style>
