<template>
    <div class="flex min-h-screen">
      <!-- Mobile Sidebar (Element UI Drawer) -->
      <el-drawer
        v-model="sidebarOpen"
        title="Меню"
        :size="300"
        :direction="'ltr'"
        :show-close="false"
        class="lg:hidden"
      >
        <ul>
          <li class="mb-4" v-if="checkPermissionsPage('admin')">
            <router-link to="/" class="text-gray-800"> <font-awesome-icon icon="chart-line" /> Дашбоард</router-link>
          </li>
          <li class="mb-4" v-if="checkPermissionsPage('admin')">
            <router-link to="/users" class="text-gray-800">Хэрэглэгчийн жагсаалт</router-link>
          </li>
          <li class="mb-4" v-if="checkPermissionsPage('admin')">
            <router-link to="/banners" class="text-gray-300 hover:text-white">Суртчилгаа жагсаалт</router-link>
          </li>
          <li class="mb-4" v-if="checkPermissionsPage('admin')">
            <router-link to="/faqs" class="text-gray-300 hover:text-white">Түгээмэл асуулт хариулт</router-link>
          </li>
          <li class="mb-4" v-if="checkPermissionsPage('admin')">
            <router-link to="/trackCodes" class="text-gray-300 hover:text-white">Track Code</router-link>
          </li>
          <li class="mb-4">
            <router-link to="/worker" class="text-gray-800">Ажилтан</router-link>
          </li>
          <li class="mb-4" v-if="checkPermissionsPage('admin')">
            <router-link to="" class="text-gray-800">Таны профайл</router-link>
          </li>
          <li class="mb-4">
            <a @click="logout" class="text-gray-800">Гарах</a>
          </li>
        </ul>
      </el-drawer>

      <!-- Desktop Sidebar (Always Visible) -->
      <aside class="hidden lg:block w-64 bg-gray-800 p-4 text-white">
        <h2 class="text-2xl font-semibold mb-8">Меню</h2>
        <ul>
          <li class="mb-4" v-if="checkPermissionsPage('admin')">
            <router-link to="/" class="text-gray-300 hover:text-white" icon="chart-line">
              <font-awesome-icon icon="chart-line" /> Дашбоард
            </router-link>
          </li>
          <li class="mb-4" v-if="checkPermissionsPage('admin')">
            <router-link to="/users" class="text-gray-300 hover:text-white">
              <font-awesome-icon icon="list" /> Хэрэглэгчийн жагсаалт
            </router-link>
          </li>
          <li class="mb-4" v-if="checkPermissionsPage('admin')">
            <router-link to="/banners" class="text-gray-300 hover:text-white">
              <font-awesome-icon icon="bullhorn" />  Суртчилгаа жагсаалт
            </router-link>
          </li>
          <li class="mb-4" v-if="checkPermissionsPage('admin')">
            <router-link to="/faqs" class="text-gray-300 hover:text-white">
              <font-awesome-icon icon="question-circle" /> Түгээмэл асуулт хариулт
            </router-link>
          </li>
          <li class="mb-4" v-if="checkPermissionsPage('admin')">
            <router-link to="/trackCodes" class="text-gray-300 hover:text-white">
              <font-awesome-icon icon="code" /> Track Code
            </router-link>
          </li>
          <li class="mb-4" v-if="checkPermissionsPage('admin')">
            <router-link to="/worker" class="text-gray-300 hover:text-white">
              <font-awesome-icon icon="fa fa-user-circle" /> Ажилтан
            </router-link>
          </li>
          <!-- <li class="mb-4">
            <router-link to="" class="text-gray-300 hover:text-white">
              <font-awesome-icon icon="user" /> Таны профайл
            </router-link>
          </li> -->
          <!-- <li class="mb-4">
            <router-link to="/settings" class="text-gray-300 hover:text-white">Тохиргоо</router-link>
          </li> -->
          <li class="mb-4">
            <a @click="logout" class="cursor-pointer text-gray-300 hover:text-white">
              <font-awesome-icon icon="sign-out-alt" /> Гарах</a>
          </li>
        </ul>
      </aside>
    </div>
  </template>

<script>
import {
  checkPermissions
} from '@/helpers/custom'
export default {
  name: 'DashboardPage',
  data () {
    return {
      sidebarOpen: false
    }
  },
  methods: {
    checkPermissionsPage (data) {
      return checkPermissions(data)
    },
    // Toggle sidebar open and close
    toggleSidebar () {
      this.sidebarOpen = !this.sidebarOpen
    },
    logout () {
      localStorage.clear()
      this.$router.push('/login')
    }
  }
}
</script>

  <style scoped>
  @media (max-width: 1024px) {
    .lg\:block {
      display: none;
    }
    .lg\:hidden {
      display: block;
    }
  }
  </style>
