import axios from 'axios'
import store from '@/store' // Vuex store-г импортлох

// API серверийн үндсэн хаяг
// const BASE_URL = 'http://localhost:3000/api'
const BASE_URL = 'https://p5afw4zfc3.execute-api.ap-southeast-1.amazonaws.com/prod/api'

// Axios хэрэглэгч үүсгэх
const apiClient = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json'
  }
})

// Axios интерцептор: Token-г Authorization header-д нэмэх
apiClient.interceptors.request.use((config) => {
  const token = store.state.token // Vuex-ээс token авах
  if (token) {
    config.headers.Authorization = `Bearer ${token}` // Authorization header-д token нэмэх
  }
  return config
}, (error) => {
  return Promise.reject(error)
})

// Функцүүдийн асинхрон үйлдлийг try-catch блоктой болгож, алдааг илүү сайн барьж өгнө.
export default {
  create: async (routeName, data) => {
    try {
      const response = await apiClient.post(`/${routeName}`, data)
      return response.data // Хариуг буцаах
    } catch (error) {
      console.error('Create Request Failed:', error) // Алдаа гарах үед console дээр алдааг харуулна
      throw new Error(error.response?.data?.message || 'An error occurred while creating the resource')
    }
  },
  update: async (routeName, data) => {
    try {
      const response = await apiClient.put(`/${routeName}/` + data.id, data)
      return response.data // Хариуг буцаах
    } catch (error) {
      console.error('Update Request Failed:', error) // Алдаа гарах үед console дээр алдааг харуулна
      throw new Error(error.response?.data?.message || 'An error occurred while updating the resource')
    }
  },
  login: async (data) => {
    try {
      const response = await apiClient.post('/login', data)
      return response.data // Хариуг буцаах
    } catch (error) {
      console.error('Login Request Failed:', error)
      throw new Error(error.response?.data?.message || 'Login failed')
    }
  },
  get: async (routeName, params = {}) => {
    try {
      const response = await apiClient.get(`/${routeName}`, { params })
      return response.data
    } catch (error) {
      console.error('GET Request Failed:', error)
      throw new Error(error.response?.data?.message || 'An error occurred while fetching data')
    }
  },
  delete: async (routeName, params = {}) => {
    try {
      const response = await apiClient.delete(`/${routeName}/` + params)
      return response.data
    } catch (error) {
      console.error('Delete Request Failed:', error)
      throw new Error(error.response?.data?.message || 'An error occurred while deleting the resource')
    }
  }
}
