<template>
    <el-dialog title="Тээврийн үнэ тооцоолох доод жин" v-model="changeDataDialog" width="400px" @close="handleClose">
      <div>
          <el-input v-if="this.transferValue === 'yuan'" v-model="formData.yuan" type="number"></el-input>
          <el-input v-if="this.transferValue === 'teevriin_huls'" v-model="formData.teevriin_huls" type="number"></el-input>
          <el-input v-if="this.transferValue === 'minimum_hemjee'" v-model="formData.minimum_hemjee" type="number"></el-input>
          <el-input v-if="this.transferValue === 'minimum_amount'" v-model="formData.minimum_amount" type="number"></el-input>
          <el-input v-if="this.transferValue === 'unit_price'" v-model="formData.unit_price" type="number"></el-input>
        </div>
        <template #footer>
          <el-button @click="handleClose">Хаах</el-button>
          <el-button type="primary" @click="submitForm()">Хадгалах</el-button>
        </template>
    </el-dialog>
</template>

<script>
import services from '../services'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'CreateEditForm',

  computed: {
    ...mapState(['changeDataDialog'])
  },
  props: {
    selectedConfig: {
      type: Object,
      default: () => ({})
    },
    transferValue: {
      type: [String]
    }
  },
  watch: {
    selectedConfig: {
      immediate: true,
      handler (newValue) {
        this.formData = { ...newValue }
      }
    }
  },
  data () {
    return {
      formData: {
        yuan: this.selectedConfig.yuan,
        teevriin_huls: this.selectedConfig.teevriin_huls,
        minimum_hemjee: this.selectedConfig.minimum_hemjee,
        minimum_amount: this.selectedConfig.minimum_amount,
        unit_price: this.selectedConfig.unit_price,
        id: this.selectedConfig.id
      }
    }
  },
  methods: {
    ...mapActions(['setChangeDataDialog']),
    handleClose () {
      this.setChangeDataDialog(false)
    },
    submitForm () {
      try {
        this.$confirm('Тооцоолол өөрчлөхдөө итгэлтэй байна уу?', 'Анхааруулга', {
          confirmButtonText: 'Тийм',
          cancelButtonText: 'Үгүй',
          type: 'warning'
        }).then(() => {
          this.$root.fullscreenLoading = true
          services.update('configsYuan', this.formData)
            .then((response) => {
              this.$notify({
                title: 'Амжилттай',
                message: 'Тооцоолол амжилттай өөрчлөгдлөө.',
                type: 'success',
                position: 'top-left'
              })
              location.reload()
              this.$root.fullscreenLoading = false
            })
        })
          .catch((error) => {
            this.$alert('Уучлаарай: ' + error, 'Алдаа', {
              confirmButtonText: 'Ойлголоо'
            })
            // console.error('Aldaa:', error)
          })
      } catch (error) {
        console.error('API Request Error:', error.message)
        this.$message.error(error.message)
      }
    }
  }
}

</script>

<style scoped>
</style>
