<template>
    <el-dialog :title="formData.id ? 'Ажилтан засварлах' : 'Шинэ хэрэглэгч үүсгэх'" v-model="createWorkerDialog" width="50%"
      @close="handleClose">
      <div>
        <el-form :model="formData" :rules="rules" ref="formRef" label-width="100px" label-position="top">
          <el-form-item label="Нэр" prop="firstName">
            <el-input v-model="formData.firstName" placeholder="Нэрийг оруулна уу" />
          </el-form-item>
          <el-form-item label="Овог" prop="lastName">
            <el-input v-model="formData.lastName" placeholder="Овог оруулна уу" />
          </el-form-item>
          <el-form-item label="И-мэйл" prop="email">
            <el-input v-model="formData.email" placeholder="И-мэйл хаягаа оруулна уу" />
          </el-form-item>
          <el-form-item label="Утасны дугаар 1" prop="phone1">
            <el-input v-model="formData.phone1" placeholder="Утасны дугаар 1 оруулна уу" />
          </el-form-item>
          <el-form-item label="Утасны дугаар 2" prop="phone2">
            <el-input v-model="formData.phone2" placeholder="Утасны дугаар 2 оруулна уу" />
          </el-form-item>
          <el-form-item label="Салбар" prop="branch_id">
            <el-select v-model="formData.branch_id" placeholder="Салбар сонгоно уу">
              <el-option v-for="item, index in branchList" :key="index" :label="item.branch_name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Албан тушаал" prop="position_id">
            <el-select v-model="formData.position_id" placeholder="Албан тушаал сонгоно уу">
              <el-option v-for="item, index in positionList" :key="index" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="success" @click="submitForm">Хадгалах</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </template>

<script>
import services from '../services' // Импорт хийж авна
import {
  ElForm,
  ElFormItem,
  ElInput,
  ElButton
} from 'element-plus'
import {
  mapState,
  mapActions
} from 'vuex'

export default {
  name: 'CreateEditForm',
  computed: {
    ...mapState(['createWorkerDialog'])
  },
  components: {
    ElForm,
    ElFormItem,
    ElInput,
    ElButton
  },
  props: {
    selectedUser: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      branchList: [],
      positionList: [],
      formData: this.selectedUser.id
        ? this.selectedUser
        : {
            firstName: '',
            lastName: '',
            email: '',
            phone1: '',
            phone2: '',
            address: '',
            password: '12345',
            position_id: null,
            branch_id: null
          },
      rules: {
        phone1: [{
          required: true,
          message: 'Утасны дугаар 1 оруулна уу',
          trigger: 'blur'
        },
        {
          pattern: /^[0-9]{8}$/,
          message: 'Утасны дугаар 8 оронтой байх ёстой',
          trigger: ['blur', 'change']
        }
        ],
        branch_id: [{
          required: true,
          message: 'Салбар сонгоно уу',
          trigger: ['blur', 'change']
        }],
        position_id: [{
          required: true,
          message: 'Албан тушаал сонгоно уу',
          trigger: ['blur', 'change']
        }]
      }
    }
  },
  created () {
    this.getAllBranch()
    this.getAllPosition()
  },
  watch: {
    selectedUser: {
      immediate: true, // Хэрэглэгчийн өгөгдөл анх ороход ажиллана
      handler (newValue) {
        this.formData = { ...newValue } // Шинэ утгуудыг formData-д хуулбарлах
      }
    }
  },
  methods: {
    ...mapActions(['setCreateUserDialog']),
    handleClose () {
      this.formData = {}
      this.setCreateUserDialog(false)
    },
    submitForm () {
      this.$refs.formRef.validate((valid) => {
        if (valid) {
          const payload = { ...this.formData }
          payload.is_worker = true
          if (!this.formData.id && !this.formData.password) {
            payload.password = '12345'
          }

          try {
            let response
            if (this.formData.id) {
              response = services.update('users', payload)
            } else {
              response = services.create('users', payload)
            }

            if (response) {
              this.handleClose()
              this.$router.push('/worker')
            }
          } catch (error) {
            console.error('API Request Error:', error.message)
            this.$message.error(error.message)
          }
        } else {
          console.log('Алдаа гарлаа, шаардлагатай талбаруудыг бөглөнө үү!')
          return false
        }
      })
    },
    async getAllBranch () {
      try {
        const response = await services.get('branches')
        this.branchList = response.data
      } catch (error) {
        console.error('Error fetching data:', error.message)
      }
    },
    async getAllPosition () {
      let response
      try {
        response = await services.get('positions')
        if (response.type === 'success') {
          this.positionList = response.data
        }
      } catch (error) {
        console.error('API Request Error:', error.message)
        this.$message.error(error.message)
      }
    }
  }
}
</script>

  <style scoped>
    .dialog-footer {
      text-align: right;
    }

  </style>
