<template>
    <div>
      <el-row class="header_zone mt-10">
        <el-col :span="12">
          Түгээмэл асуулт хариулт удирдах хэсэг
        </el-col>
        <el-col :span="12" v-if="checkPermissionsPage('admin')">
          <el-button class="flr" @click="createFAQsDialogOpen()" type="success">Түгээмэл асуулт хариулт үүсгэх</el-button>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <el-card class="mt-10">
            <el-table :data="data" style="width: 100%" border class="mt-10">
              <el-table-column label="Асуулт" prop="answer"></el-table-column>
              <el-table-column label="Хариулт" prop="question"></el-table-column>
              <el-table-column label="Төлөв" prop="is_active" v-if="checkPermissionsPage('admin')">
                <template #default="scope">
                  <el-switch @change="changeStatus(scope.row)" v-model="scope.row.is_active" class="ml-2" inline-prompt style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949" active-text="Идэвхтэй" inactive-text="Идэвхгүй" />
                </template>
              </el-table-column>
              <el-table-column label="Үйлдэл" width="250" v-if="checkPermissionsPage('admin')">
                <template v-slot="scope">
                  <el-button v-if="checkPermissionsPage('admin')" @click="createFAQsDialogOpen(scope.row)">Засварлах</el-button>
                  <el-button v-if="checkPermissionsPage('admin')" @click="deleteRow(scope.row)" type="danger">Устгах</el-button>
                </template>
              </el-table-column>
            </el-table>
            <el-pagination @current-change="handlePageChange" :current-page="currentPage" :page-size="pageSize"
              :total="data.length" layout="total, prev, pager, next, jumper"
              style="margin-top: 20px; text-align: right;" />
          </el-card>
        </el-col>

      </el-row>
      <CreateFaqsDialog :selectedFAQs="this.targetFAQs" />
    </div>
  </template>
<script>
import services from '@/services'
import { ElMessage, ElMessageBox } from 'element-plus'
import { mapActions, mapGetters } from 'vuex'
import CreateFaqsDialog from './createFAQsDialog.vue'
import { checkPermissions } from '@/helpers/custom'

export default {
  name: 'UserListPage',
  components: {
    CreateFaqsDialog
  },
  created () {
    this.loadData()
  },
  data () {
    return {
      currentPage: 1,
      pageSize: 5,
      data: [],
      columns: [],
      targetFAQs: {}
    }
  },
  computed: {
    ...mapGetters([
      'cdnGetter'
    ])
  },
  methods: {
    ...mapActions(['setCreateFAQsDialog']),
    async loadData () {
      try {
        const response = await services.get('faqs')
        if (response) {
          this.data = response.data
          this.totalRecords = response.data.length
        } else {
          ElMessage.error('Өгөгдөл олдсонгүй!')
        }
      } catch (error) {
        console.error('Error fetching data: ', error)
        ElMessage.error(`Өгөгдөл ачаалахад алдаа гарлаа: ${error.message}`)
      }
    },
    checkPermissionsPage (data) {
      return checkPermissions(data)
    },
    handlePageChange (page) {
      this.currentPage = page
    },
    editRow (row) {
      console.log('Edit Row: ', row)
    },
    createFAQsDialogOpen (data) {
      this.targetFAQs = data
      this.setCreateFAQsDialog(true)
    },
    async deleteRow (row) {
      try {
        await ElMessageBox.confirm('Та энэ бичлэгийг устгахдаа итгэлтэй байна уу?', 'Баталгаажуулалт', {
          confirmButtonText: 'Тийм',
          cancelButtonText: 'Үгүй',
          type: 'warning'
        })
        await services.delete('faqs', row.id)
        this.loadData()
        ElMessage.success('Амжилттай устгагдлаа!')
        const index = this.data.indexOf(row)
        if (index > -1) {
          this.data.splice(index, 1)
        }
      } catch (error) {
        if (error !== 'cancel') {
          ElMessage.error('Устгахад алдаа гарлаа!')
        }
      }
    },
    async changeStatus (data) {
      try {
        const response = await services.update('faqs', data)
        if (response && response.data) {
          ElMessage.success('Төлөв амжилттай өөрчлөгдлөө!')
        }
      } catch (error) {
        console.error('Aldaa: ', error.message)
        this.$message.error(error.message)
      }
    }
  }
}

</script>
  <style lang="scss">
    .header_zone {
      background-color: white;
      padding: 20px;
    }

    .flr {
      float: right;
    }

    .el-table {
      margin-bottom: 20px;
    }

  </style>
