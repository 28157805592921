<template>
  <el-dialog :title="formData.id ? 'Засварлах' : 'Шинэ хэрэглэгч үүсгэх'" v-model="createUserDialog" width="50%"
    @close="handleClose">
    <div>
      <el-form :model="formData" :rules="rules" ref="formRef" label-width="100px" label-position="top">
        <el-form-item label="Нэр" prop="firstName">
          <el-input v-model="formData.firstName" placeholder="Нэрийг оруулна уу" />
        </el-form-item>
        <el-form-item label="Овог" prop="lastName">
          <el-input v-model="formData.lastName" placeholder="Овог оруулна уу" />
        </el-form-item>
        <el-form-item label="И-мэйл" prop="email">
          <el-input v-model="formData.email" placeholder="И-мэйл хаягаа оруулна уу" />
        </el-form-item>
        <el-form-item label="Утасны дугаар 1" prop="phone1">
          <el-input v-model="formData.phone1" placeholder="Утасны дугаар 1 оруулна уу" />
        </el-form-item>
        <el-form-item label="Утасны дугаар 2" prop="phone2">
          <el-input v-model="formData.phone2" placeholder="Утасны дугаар 2 оруулна уу" />
        </el-form-item>
        <el-form-item>
          <el-button type="success" @click="submitForm">Хадгалах</el-button>
          <el-button @click="resetForm()">Цэвэрлэх</el-button>
        </el-form-item>
      </el-form>
    </div>
  </el-dialog>
</template>

<script>
import services from '../services'
import {
  ElForm,
  ElFormItem,
  ElInput,
  ElButton
} from 'element-plus'
import {
  mapState,
  mapActions
} from 'vuex'

export default {
  name: 'CreateEditForm',
  computed: {
    ...mapState(['createUserDialog'])
  },
  components: {
    ElForm,
    ElFormItem,
    ElInput,
    ElButton
  },
  props: {
    selectedUser: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      formData: this.selectedUser.id
        ? this.selectedUser
        : {
            firstName: '',
            lastName: '',
            email: '',
            phone1: '',
            phone2: '',
            address: '',
            password: '12345'
          },
      rules: {
        phone1: [{
          required: true,
          message: 'Утасны дугаар 1 оруулна уу',
          trigger: 'blur'
        },
        {
          pattern: /^[0-9]{8}$/,
          message: 'Утасны дугаар 8 оронтой байх ёстой',
          trigger: ['blur', 'change']
        }
        ],
        phone2: [{
          pattern: /^[0-9]{8}$/,
          message: 'Утасны дугаар 8 оронтой байх ёстой',
          trigger: ['blur', 'change']
        }]
      },
      branchList: []
    }
  },
  watch: {
    selectedUser: {
      immediate: true, // Хэрэглэгчийн өгөгдөл анх ороход ажиллана
      handler (newValue) {
        this.formData = { ...newValue } // Шинэ утгуудыг formData-д хуулбарлах
      }
    }
  },
  methods: {
    ...mapActions(['setCreateUserDialog']),
    handleClose () {
      this.formData = {}
      this.setCreateUserDialog(false)
    },
    submitForm () {
      this.$refs.formRef.validate((valid) => {
        if (valid) {
          const payload = { ...this.formData }
          payload.is_worker = false
          if (!this.formData.id && !this.formData.password) {
            payload.password = '12345'
          }

          try {
            this.$confirm('Хэрэглэгч бүртгэх үү?', 'Анхааруулга', {
              confirmButtonText: 'Тийм',
              cancelButtonText: 'Үгүй',
              type: 'warning'
            }).then(() => {
              const apiCall = this.formData.id
                ? services.update(this.$route.name, payload)
                : services.create(this.$route.name, payload)

              apiCall
                .then((response) => {
                  if (response.type === 'unsuccess') {
                    this.$notify({
                      title: 'Алдаа',
                      message: response.message,
                      type: 'error',
                      position: 'top-left'
                    })
                  } else {
                    this.$notify({
                      title: 'Амжилттай',
                      message: 'Мэдээлэл амжилттай хадгалагдлаа.',
                      type: 'success',
                      position: 'top-left'
                    })
                    this.handleClose()
                    location.reload()
                  }
                })
                .catch((error) => {
                  console.error('Алдаа:', error)
                  this.$notify({
                    title: 'Алдаа',
                    message: error.message || 'Сүлжээний алдаа гарлаа.',
                    type: 'error',
                    position: 'top-left'
                  })
                })
            })
          } catch (error) {
            console.error('API алдаа:', error.message)
            this.$message.error(error.message)
          }
        } else {
          console.log('Алдаа гарлаа, шаардлагатай талбаруудыг бөглөнө үү!')
          return false
        }
      })
    },
    resetForm () {
      this.formData.firstName = null
      this.formData.lastName = null
      this.formData.email = null
      this.formData.phone1 = null
      this.formData.phone2 = null
    }
  }
}
</script>

<style scoped>
  .dialog-footer {
    text-align: right;
  }

</style>
