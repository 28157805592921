<template>
    <div class="min-h-screen flex justify-center items-center bg-white">
      <div class="bg-white p-8 rounded-xl shadow-md w-full sm:w-96">
        <h2 class="text-3xl font-semibold text-center text-gray-800 mb-6">Бүртгүүлэх</h2>

        <!-- Validation Error Messages -->
        <div v-if="errorMessage" class="text-red-500 text-center mb-4">{{ errorMessage }}</div>

        <form @submit.prevent="handleRegister" class="space-y-6">
          <div>
            <label for="phone" class="block text-gray-700">Утасны дугаар</label>
            <input
              id="phone"
              type="text"
              v-model="form.phone"
              required
              :class="{'border-red-500': phoneError}"
              class="w-full p-3 mt-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Утасны дугаараа оруулна уу"
              @blur="validatePhone"
            />
            <span v-if="phoneError" class="text-red-500 text-sm">Зөв утасны дугаар оруулна уу.</span>
          </div>

          <div>
            <label for="password" class="block text-gray-700">Нууц үг</label>
            <input
              id="password"
              type="password"
              v-model="form.password"
              required
              :class="{'border-red-500': passwordError}"
              class="w-full p-3 mt-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Нууц үгээ оруулна уу"
              @blur="validatePassword"
            />
            <span v-if="passwordError" class="text-red-500 text-sm">Нууц үг нь хамгийн багадаа 6 тэмдэгт байх ёстой.</span>
          </div>

          <div>
            <label for="confirmPassword" class="block text-gray-700">Нууц үг давтан оруулах</label>
            <input
              id="confirmPassword"
              type="password"
              v-model="form.confirmPassword"
              required
              :class="{'border-red-500': confirmPasswordError}"
              class="w-full p-3 mt-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Нууц үгээ давтан оруулна уу"
              @blur="validateConfirmPassword"
            />
            <span v-if="confirmPasswordError" class="text-red-500 text-sm">Нууц үг давтах нь таарахгүй байна.</span>
          </div>

          <div>
            <button
              type="submit"
              :disabled="phoneError || passwordError || confirmPasswordError"
              class="w-full py-3 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-300 disabled:bg-gray-300"
            >
              Бүртгүүлэх
            </button>
          </div>
        </form>

        <div class="text-center mt-4">
          <p class="text-sm text-gray-600">Танд бүртгэлтэй юу? <router-link to="/login" class="text-blue-500">Нэвтрэх</router-link></p>
        </div>
      </div>
    </div>
  </template>

<script>
import { registerUser } from '../services'

export default {
  name: 'RegisterPage',
  data () {
    return {
      form: {
        phone: '',
        password: '',
        confirmPassword: ''
      },
      errorMessage: '',
      phoneError: false,
      passwordError: false,
      confirmPasswordError: false
    }
  },
  methods: {
    async handleRegister () {
      this.errorMessage = '' // Reset error message before making the API call

      // Validate form before API request
      if (!this.form.phone || !this.form.password || !this.form.confirmPassword) {
        this.errorMessage = 'Бүх талбаруудыг бөглөнө үү.'
        return
      }

      if (this.form.password !== this.form.confirmPassword) {
        this.errorMessage = 'Нууц үг давтсангүй.'
        return
      }

      try {
        const response = await registerUser(this.form)
        console.log('Бүртгүүлэх амжилттай:', response)
        // Redirect user to login page after successful registration
        this.$router.push('/login') // Adjust as per your routing structure
      } catch (error) {
        this.errorMessage = error.message || 'Бүртгүүлэхэд алдаа гарлаа'
      }
    },
    validatePhone () {
      const regex = /^[0-9]{8}$/ // 8 оронтой утасны дугаар шалгах
      this.phoneError = !regex.test(this.form.phone)
    },
    validatePassword () {
      this.passwordError = this.form.password.length < 6
    },
    validateConfirmPassword () {
      this.confirmPasswordError = this.form.password !== this.form.confirmPassword
    }
  }
}
</script>

  <style scoped>
  body {
    margin: 0;
    font-family: 'Arial', sans-serif;
  }
  </style>
