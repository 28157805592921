<template>
    <div>
      <el-row class="header_zone mt-10">
        <el-col :span="12">
          Ажилтан удирдах хэсэг
        </el-col>
        <el-col :span="12" v-if="checkPermissionsPage('admin')">
          <el-button class="flr" @click="createWorkerDialogOpen()" type="success">Шинэ ажилтан үүсгэх</el-button>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-card class="mt-10">
          <el-table style="width: 100%" border class="mt-10" :data="data">
            <el-table-column label="№" type="index"></el-table-column>
            <el-table-column label="Утасны дугаар" prop="phone1" width="120"></el-table-column>
            <el-table-column label="Овог" prop="lastName" width="150"></el-table-column>
            <el-table-column label="Нэр" prop="firstName" width="150"></el-table-column>
            <el-table-column label="Салбар">
              <template v-slot="scope">
                    {{ scope.row?.Branch?.branch_name || '' }}
                </template>
            </el-table-column>
            <el-table-column label="Албан тушаал" width="150">
                <template v-slot="scope">
                    {{ scope.row?.Position?.name || '' }}
                </template>
            </el-table-column>
            <el-table-column label="Үйлдэл" width="350" v-if="checkPermissionsPage('admin')">
              <template v-slot="scope">
                <el-button v-if="checkPermissionsPage('admin')" @click="createWorkerDialogOpen(scope.row)">Засварлах</el-button>
                <el-button v-if="checkPermissionsPage('admin')" @click="deleteRow(scope.row)" type="danger">Устгах</el-button>
              </template>
            </el-table-column>
          </el-table>

          <!-- Pagination -->
          <el-pagination
            @current-change="handlePageChange"
            :current-page="currentPage"
            :page-size="pageSize"
            :total="totalRecords"
            layout="total, prev, pager, next, jumper"
            style="margin-top: 20px; text-align: right;" />
          </el-card>
        </el-col>

      </el-row>
      <CreateWorkerDialog :selectedUser="this.targetUser"/>
    </div>
  </template>
<script>
import services from '@/services'
import { checkPermissions } from '@/helpers/custom'
import { ElMessage, ElMessageBox } from 'element-plus'
import CreateWorkerDialog from './createWorkerDialog.vue'
import { mapActions } from 'vuex'

export default {
  name: 'WorkerListPage',
  components: {
    CreateWorkerDialog
  },
  created () {
    this.loadData()
  },
  data () {
    return {
      createAddressDialog: false,
      currentPage: 1,
      pageSize: 10,
      data: [{
        Position: []
      }],
      columns: [],
      targetUser: {}
    }
  },
  methods: {
    ...mapActions(['setCreateWorkerDialog']),
    async loadData () {
      try {
        const response = await services.get('workers', {
          params: {
            page: this.currentPage, // Одоогийн хуудас
            limit: this.pageSize // Хуудасны хэмжээ
          }
        })
        if (response) {
          this.data = response.data
          this.totalRecords = response.total// Pagination тоо
          this.totalPages = response.totalPages
        } else {
          // Хариулт дотор data байхгүй бол алдаа харуулна
          ElMessage.error('Өгөгдөл олдсонгүй!')
        }
      } catch (error) {
        console.error('Error fetching data: ', error)
        // Алдааг консолоор илүү дэлгэрэнгүй харуулах
        ElMessage.error(`Өгөгдөл ачаалахад алдаа гарлаа: ${error.message}`)
      }
    },
    checkPermissionsPage (data) {
      return checkPermissions(data)
    },
    handlePageChange (page) {
      this.currentPage = page
      this.loadData()
    },
    editRow (row) {
      // Edit functionality here
      console.log('Edit Row: ', row)
      // Open dialog for editing...
    },
    createWorkerDialogOpen (data) {
      this.targetUser = data
      this.setCreateWorkerDialog(true)
    },
    createAddressDialogOpen (data) {
      this.targetUser = data
      this.setCreateAddressDialog(true)
    },
    async deleteRow (row) {
      try {
        await ElMessageBox.confirm('Та энэ бичлэгийг устгахдаа итгэлтэй байна уу?', 'Баталгаажуулалт', {
          confirmButtonText: 'Тийм',
          cancelButtonText: 'Үгүй',
          type: 'warning'
        })

        // API устгах хүсэлт явуулах
        await services.delete('users', row.id)

        // Success message
        ElMessage.success('Амжилттай устгагдлаа!')
        this.loadData()
        // Remove row from data after deletion
        const index = this.data.indexOf(row)
        if (index > -1) {
          this.data.splice(index, 1)
        }
      } catch (error) {
        if (error !== 'cancel') {
          ElMessage.error('Устгахад алдаа гарлаа!')
        }
      }
    }
  }
}

</script>
  <style lang="scss">
    .header_zone {
      background-color: white;
      padding: 20px;
    }

    .flr {
      float: right;
    }

    .el-table {
      margin-bottom: 20px;
    }

  </style>
