<template>
    <div class="min-h-screen flex justify-center items-center bg-white">
      <div class="bg-white p-8 rounded-xl shadow-md w-full sm:w-96">
        <h2 class="text-3xl font-semibold text-center text-gray-800 mb-6">Нэвтрэх</h2>

        <!-- Validation Error Messages -->
        <div v-if="errorMessage" class="text-red-500 text-center mb-4">{{ errorMessage }}</div>

        <form @submit.prevent="handleLogin" class="space-y-6">
          <div>
            <label for="phone1" class="block text-gray-700">Утасны дугаар</label>
            <input
              id="phone1"
              type="text"
              v-model="form.phone1"
              required
              :class="{'border-red-500': phone1Error}"
              class="w-full p-3 mt-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Утасны дугаараа оруулна уу"
              @blur="validatephone1"
            />
            <span v-if="phone1Error" class="text-red-500 text-sm">Зөв утасны дугаар оруулна уу.</span>
          </div>

          <div>
            <label for="password" class="block text-gray-700">Нууц үг</label>
            <input
              id="password"
              type="password"
              v-model="form.password"
              required
              :class="{'border-red-500': passwordError}"
              class="w-full p-3 mt-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Нууц үгээ оруулна уу"
              @blur="validatePassword"
            />
            <span v-if="passwordError" class="text-red-500 text-sm">Нууц үг нь хамгийн багадаа 6 тэмдэгт байх ёстой.</span>
          </div>

          <div>
            <button
              type="submit"
              :disabled="phone1Error || passwordError"
              class="w-full py-3 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-300 disabled:bg-gray-300"
            >
              Нэвтрэх
            </button>
          </div>
        </form>

        <div class="text-center mt-4">
          <p class="text-sm text-gray-600">Танд бүртгэлгүй юу? <router-link to="/register" class="text-blue-500">Бүртгүүлэх</router-link></p>
        </div>
      </div>
    </div>
  </template>

<script>
import services from '../services'
import { mapActions } from 'vuex'

export default {
  name: 'LoginPage',
  data () {
    return {
      form: {
        phone1: '',
        password: ''
      },
      errorMessage: '',
      phone1Error: false,
      passwordError: false
    }
  },
  methods: {
    ...mapActions(['setToken', 'setUser', 'setRole', 'setPermissions']),

    async handleLogin () {
      this.errorMessage = ''

      // Validate form before API request
      if (!this.form.phone1 || !this.form.password) {
        this.errorMessage = 'Бүх талбаруудыг бөглөнө үү.'
        return
      }

      try {
        const response = await services.login(this.form)
        if (response.token) {
          this.setToken(response.token)
          this.setUser(response.user)
          this.setPermissions(response.permissions)
          this.$router.push('/') // Adjust as per your routing structure
        }
      } catch (error) {
        this.errorMessage = error.message || 'Нэвтрэхэд алдаа гарлаа'
      }
    },
    validatephone1 () {
      const regex = /^[0-9]{8}$/
      this.phone1Error = !regex.test(this.form.phone1)
    },
    validatePassword () {
      this.passwordError = this.form.password.length < 3
    }
  }
}
</script>

  <style scoped>
  body {
    margin: 0;
    font-family: 'Arial', sans-serif;
  }
  </style>
