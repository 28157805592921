<template>
  <div>
    <el-dialog
      v-model="createConfigDetailDialog"
      title="Ачааны төрөл удирдах"
      :before-close="handleCloseConfigDialog"
      width="800px"
      class="custom-dialog"
    >
      <div v-if="is_show === 'show'">
        <el-form
          :model="cargoTypeForm"
          :rules="rules"
          ref="cargoTypeFormRef"
          label-width="120px"
          label-position="top"
        >
          <!-- Ачааны төрөл -->
          <el-form-item label="Төрлийн нэр" prop="name">
            <el-input
              v-model="cargoTypeForm.name"
              placeholder="Төрлийн нэр оруулна уу"
            />
          </el-form-item>
          <el-form-item label="Тайлбар" prop="description">
            <el-input
              type="textarea"
              v-model="cargoTypeForm.description"
              placeholder="Тайлбар оруулна уу"
              :rows="4"
            />
          </el-form-item>
          <el-form-item label="Эзэлхүүн тооцох" prop="is_ezelhvvn">
            <el-radio-group v-model="cargoTypeForm.is_ezelhvvn">
              <el-radio :value="true">Тийм</el-radio>
              <el-radio :value="false">Үгүй</el-radio>
            </el-radio-group>
          </el-form-item>

          <!-- Үнийн жагсаалт (Dynamic Form) -->
          <el-divider>Үнийн ангилал</el-divider>
          <div v-for="(price, index) in cargoTypeForm.ConfigReferences" :key="index" class="dynamic-form">
            <el-row :gutter="15">
              <el-col :span="7">
                <el-form-item :label="'Доод жин ' + (index + 1)" :prop="'ConfigReferences' + index + '.min'">
                  <el-input-number
                    v-model="price.min"
                    :min="0"
                    :controls="false"
                    placeholder="Доод жин"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="7">
                <el-form-item :label="'Дээд жин ' + (index + 1)" :prop="'ConfigReferences' + index + '.max'">
                  <el-input-number
                    v-model="price.max"
                    :min="0"
                    :controls="false"
                    placeholder="Дээд жин"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="7">
                <el-form-item :label="'Үнэ ' + (index + 1)" :prop="'ConfigReferences' + index + '.price'">
                  <el-input-number
                    v-model="price.price"
                    :min="0"
                    :controls="false"
                    placeholder="Үнэ"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="3" class="remove-button">
                <el-button
                  type="danger"
                  icon="el-icon-delete"
                  circle
                  @click="removeconfigReferences(index)"
                />
              </el-col>
            </el-row>
          </div>
          <el-button type="success" plain @click="addconfigReferences">
            Үнийн ангилал нэмэх
          </el-button>

          <!-- Товчс ккк -->
          <div class="dialog-footer">
            <el-button @click="handleClose">Болих</el-button>
            <el-button type="primary" @click="submitForm">Хадгалах</el-button>
          </div>
        </el-form>
      </div>

      <!-- Тооцоолол нэмэх товч -->
      <el-button
        v-if="is_show_button === true"
        type="success"
        @click="chanageValue('show')"
      >
        Тооцоолол нэмэх
      </el-button>

      <!-- Тооцоолол харуулах хэсэг -->
      <el-card shadow="hover" class="config-summary-card">
        <el-row>
          <el-col>
            <p>Нэг юанийг тооцох төгрөгийн дүн: <strong>{{ config.yuan }}</strong></p>
            <p>1 м3 ачаа тээвэрлэх үнэ: <strong>{{ config.teevriin_huls }}</strong></p>
            <el-divider></el-divider>
          </el-col>
          <el-col
            v-for="(item, index) in config.ConfigType"
            :key="index"
            class="config-item"
          >
            <el-tag type="primary">{{ item.name }}</el-tag> -  <el-button type="danger" size="small" @click="configTypeDelete(item)">хэмжигдэхүүний төрөл устгах</el-button><br><br>
            <p>Тайлбар: {{ item.description }}</p>
            <p>
              Эзэлхүүн тооцох эсэх:
              <strong>{{ item.is_ezelhvvn ? 'Тооцно' : 'Тооцохгүй' }}</strong>
            </p><br>
            <el-table :data="item.ConfigReferences" size="mini" border>
              <el-table-column prop="min" label="Доод утга" />
              <el-table-column prop="max" label="Дээд утга" />
              <el-table-column prop="price" label="Харгалзах үнэ" />
              <el-table-column label="Үйлдэл">
                <template #default="{ row }">
                  <el-button
                    size="small"
                    type="danger"
                    @click="deleteConfigReferences(row)"
                  >
                    Хэмжигдэхүүн устгах
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
      </el-card>
    </el-dialog>
  </div>
</template>

<script>
import services from '@/services'
import axios from 'axios'
import { ElMessage, ElMessageBox } from 'element-plus'
import { mapActions, mapState } from 'vuex'
export default {
  name: 'CargoDetail',
  props: {
    selectConfig: Object,
    selectTypePass: Object
  },
  data () {
    return {
      dialogVisible: false,
      cargoTypes: [],
      cargoTypeForm: {
        id: null,
        name: '',
        description: '',
        is_ezelhvvn: false,
        config_id: null,
        ConfigReferences: []
      },
      configType: {},
      config: {},
      rules: {
        name: [{ required: true, message: 'Нэр оруулна уу', trigger: 'blur' }],
        ConfigReferences: [
          { required: true, message: 'Үнийн жагсаалтыг бөглөнө үү', trigger: 'blur' }
        ],
        is_ezelhvvn: [{ required: true, message: 'Сонгоно уу', trigger: 'blur' }]
      },
      is_show: null,
      is_show_button: true
    }
  },
  watch: {
    selectConfig: {
      immediate: true,
      handler (newValue) {
        this.config = {
          ...newValue
        }
      }
    },
    selectTypePass: {
      immediate: true,
      handler (newValue) {
        this.configType = {
          ...newValue
        }
      }
    }
  },
  computed: {
    ...mapState(['createConfigDetailDialog'])
  },
  methods: {
    ...mapActions(['setCreateConfigDetailDialog']),
    openDialog () {
      this.resetForm()
      this.dialogVisible = true
    },
    handleCloseConfigDialog () {
      this.resetForm()
      this.setCreateConfigDetailDialog(false)
      this.is_show_button = true
      this.is_show = 'hidden'
    },
    handleClose () {
      this.is_show_button = true
      this.is_show = 'hidden'
    },
    resetForm () {
      this.cargoTypeForm = {
        id: null,
        name: '',
        description: '',
        configReferences: []
      }
    },
    addconfigReferences () {
      if (!this.cargoTypeForm.ConfigReferences) {
        this.cargoTypeForm.ConfigReferences = []
      }
      this.cargoTypeForm.ConfigReferences.push({ min: 0, max: 0, price: 0 })
    },
    removeconfigReferences (index) {
      this.cargoTypeForm.ConfigReferences.splice(index, 1)
    },
    async submitForm () {
      this.$refs.cargoTypeFormRef.validate(async (valid) => {
        if (valid) {
          if (!Array.isArray(this.cargoTypeForm.ConfigReferences) || this.cargoTypeForm.ConfigReferences.length === 0) {
            this.$notify({
              title: 'Алдаа',
              message: 'Үнийн жагсаалтыг хоосон байж болохгүй.',
              type: 'error',
              position: 'top-left'
            })
            return
          }
          const payload = { ...this.cargoTypeForm }
          payload.config_id = this.config.id
          // payload.is_ezelhvvn = this.is_ezelhvvn
          try {
            // const apiCall = this.cargoTypeForm.id
            //   ? services.update('configTypeAndReferences', payload)
            //   : services.create('configTypeAndReferences', payload)
            const apiCall = services.create('configTypeAndReferences', payload)

            await apiCall
            this.$notify({
              title: 'Амжилттай',
              message: 'Мэдээлэл амжилттай хадгалагдлаа.',
              type: 'success'
            })
            this.handleCloseConfigDialog()
          } catch (error) {
            console.error('API Request Error:', error.message)
            this.$notify({
              title: 'Алдаа',
              message: error.message || 'Сүлжээний алдаа гарлаа.',
              type: 'error'
            })
          }
        }
      })
    },
    editCargoType (row) {
      this.cargoTypeForm = { ...row }
      this.dialogVisible = true
    },
    async deleteCargoType (id) {
      await axios.delete(`/api/cargo-types/${id}`)
      this.fetchCargoTypes()
    },
    chanageValue (data) {
      this.is_show = data
      this.is_show_button = false
    },
    async deleteConfigReferences (row) {
      try {
        await ElMessageBox.confirm('Та энэ бичлэгийг устгахдаа итгэлтэй байна уу?', 'Баталгаажуулалт', {
          confirmButtonText: 'Тийм',
          cancelButtonText: 'Үгүй',
          type: 'warning'
        })
        console.log('Устгах ID:', row.id)
        await services.delete('configReferences', row.id)
        if (Response.type === 'success') {
          this.setCreateConfigDetailDialog(false)
          this.$notify({
            title: 'Амжилттай',
            message: 'Мэдээлэл амжилттай устгагдлаа.',
            type: 'success'
          })
        }
      } catch (error) {
        console.error('Устгах явцад алдаа гарлаа:', error)
        if (error !== 'cancel') {
          ElMessage.error('Устгахад алдаа гарлаа! Сервертэй холбогдож чадаагүй эсвэл алдаатай өгөгдөл байна.')
        }
      }
    },
    async configTypeDelete (row) {
      try {
        await ElMessageBox.confirm('Та энэ бичлэгийг устгахдаа итгэлтэй байна уу?', 'Баталгаажуулалт', {
          confirmButtonText: 'Тийм',
          cancelButtonText: 'Үгүй',
          type: 'warning'
        })
        console.log('Устгах ID:', row.id)
        await services.delete('configTypes', row.id)
        if (Response.type === 'success') {
          ElMessage.success('Амжилттай устгагдлаа!')
        }
      } catch (error) {
        console.error('Устгах явцад алдаа гарлаа:', error)
        if (error !== 'cancel') {
          ElMessage.error('Устгахад алдаа гарлаа! Сервертэй холбогдож чадаагүй эсвэл алдаатай өгөгдөл байна.')
        }
      }
    }
  }
}
</script>

<style scoped>
.custom-dialog {
  max-width: 800px;
}
.dynamic-form {
  margin-bottom: 20px;
  padding: 10px 0;
  border-bottom: 1px dashed #ccc;
}
.dialog-footer {
  text-align: right;
  margin-top: 20px;
}
.remove-button {
  display: flex;
  align-items: center;
  justify-content: center;
}
.config-summary-card {
  margin-top: 20px;
}
.config-item {
  margin-bottom: 20px;
}
</style>
