<template>
  <div>
    <el-upload
    name="file"
    class="avatar-uploader"
    :show-file-list="true"
    :headers="{'Authorization':'Bearer '+ token }"
    action="http://localhost:3000/api/upload"
    :on-preview="handlePreview"
    :on-change="success"
    :accept="acceptType()"
    :on-remove="handleRemove"
    :before-remove="beforeRemove"
    :limit="this.fileLimit"
    :v-model="this.cdnGetter+this.imgUrlGetter"
    :on-exceed="handleExceed"
    :file-list="fileListAnswer">
      <img v-if="this.imgUrlGetter" :src="this.cdnGetter+this.imgUrlGetter" class="avatar">
      <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>
    </el-upload>
  </div>
</template>

<script>
import {
  mapGetters,
  mapActions
} from 'vuex'
import { Plus } from '@element-plus/icons-vue'
import axios from 'axios'
// import services from '@/helpers/services.js'
export default {
  components: {
    Plus
  },
  computed: {
    ...mapGetters(['token', 'imgUrlGetter', 'cdnGetter'])
  },
  data () {
    return {
      disabled: false,
      fileListAnswer: [],
      fileList: [],
      isShow: false,
      cdn: 'https://daagiigaadskycargo.s3.ap-southeast-1.amazonaws.com',
      fileTitle: '',
      fileTitleAnswer: 'Та хариултын файлыг хуулна уу',
      // centerDialogVisible:false
      fileLimit: 1
    }
  },
  methods: {
    ...mapActions(['setDynamicImgUrl']), // Vuex actions ашиглах

    success (file, fileList) {
      if (file.response) {
        // this.imageUrl = file.response.data.location
        this.setDynamicImgUrl(file.response.data.location)
      }
      setTimeout(() => {
        this.isShow = true
      }, 10000)
    },
    acceptType (index) {
      return 'image/png, image/jpeg'
    },
    async handleRemove (file, fileList) {
      // Файлын URL-ээс шаардлагатай замыг гаргаж авах
      // eslint-disable-next-line no-prototype-builtins
      if (!file.hasOwnProperty('response')) {
        let response = file.url.split('?')[0]
        response = response.split('.com/')[1]
        file.response = response
      }
      try {
        // Axios ашиглан зураг устгах хүсэлт явуулах
        const response = await axios.post('http://localhost:3000/api/deleteFile', {
          bucketName: 'daagiigaadskycargo',
          fileName: file.response.data.location
        })

        console.log('Устгах хүсэлтийн хариу:', response.data)

        // Файлын жагсаалт шинэчлэх болон бусад утгуудыг тохируулах
        this.setDynamicImgUrl(null)
        this.isShow = false
      } catch (error) {
        console.error('Зураг устгах үед алдаа гарлаа:', error)
      }
    },
    beforeRemove (file, fileList) {
      return this.$confirm(`Сонгосон  ${file.name}  файлыг хавсаргасан файлын жагсаалтаас хасах уу?`)
    },
    handlePreview (file) {
      console.log(file)
    },
    handleExceed (files, fileList) {
      this.$message.warning(
          `Файл тоо хэтэрсэн байна. ${
                    files.length
                } файл хуулсан байна. ${
                    this.fileLimit
                } хэтрэхгүй файл хуулна уу.`
      )
    },
    handleUploadVariantfulImages (file, fileList) {
      this.fileList = fileList
    }
  }
}

</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed var(--el-border-color);
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  text-align: center;
}
</style>
