import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './assets/tailwind.css'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'

library.add(fas)

const app = createApp(App)
app.component('font-awesome-icon', FontAwesomeIcon)
app.use(ElementPlus)
app.use(router)
app.use(store)
app.mount('#app')

app.config.globalProperties.$formatCurrency = function (value) {
  return new Intl.NumberFormat().format(value) + '₮'
}
app.config.globalProperties.$formatCurrencyComma = function (value) {
  return new Intl.NumberFormat().format(value)
}
