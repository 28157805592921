<template>
  <el-dialog :title="formData.id ? 'Засварлах' : 'Шинэ баннер үүсгэх'" v-model="createBannerDialog" width="50%"
    @close="handleClose">
    <div>
      <el-form :model="formData" :rules="rules" ref="formRef" label-width="120px">
        <el-form-item label="Гарчиг" prop="title">
          <el-input v-model="formData.title" placeholder="Гарчгийг оруулна уу"></el-input>
        </el-form-item>
        <el-form-item label="Тайлбар" prop="description">
          <el-input type="textarea" v-model="formData.description" placeholder="Тайлбарыг оруулна уу"></el-input>
        </el-form-item>
        <el-form-item label="Зурагны зам" prop="image_path">
            <DynamicUploadImage/>
        </el-form-item>
        <el-form-item label="Идэвхтэй эсэх" prop="is_active">
            <el-switch v-model="formData.is_active"></el-switch>
          </el-form-item>
        <!-- <el-form-item label="Эрэмбэ" prop="sort">
          <el-input-number v-model="formData.sort" :min="0" placeholder="Эрэмбийг оруулна уу"></el-input-number>
        </el-form-item> -->
        <el-form-item>
          <el-button type="primary" @click="submitForm">Хадгалах</el-button>
          <!-- <el-button @click="resetForm">Цэвэрлэх</el-button> -->
        </el-form-item>
      </el-form>
    </div>
  </el-dialog>
</template>

<script>
import DynamicUploadImage from '@/components/dynamicUploadImage.vue'
import services from '../services' // Импорт хийж авна

import {
  ElForm,
  ElFormItem,
  ElInput,
  ElButton
} from 'element-plus'
import {
  mapActions,
  mapState
} from 'vuex'

export default {
  name: 'CreateEditForm',

  components: {
    ElForm,
    ElFormItem,
    ElInput,
    ElButton,
    DynamicUploadImage
  },
  computed: {
    ...mapState(['createBannerDialog', 'dynamic_img_url'])
  },
  props: {
    selectedBanner: {
      type: Object,
      default: () => ({})
    }

  },
  created () {
    // this.checkEdit()
  },
  data () {
    return {
      formData: {
        title: '1',
        description: '1',
        image_path: '1',
        is_active: true
      },
      rules: {
        title: [{
          required: true,
          message: 'Гарчгийг оруулна уу',
          trigger: 'blur'
        }],
        description: [{
          required: true,
          message: 'Тайлбарыг оруулна уу',
          trigger: 'blur'
        }],
        image_path: [{
          required: true,
          message: 'Зурагны замыг оруулна уу',
          trigger: 'blur'
        }],
        sort: [{
          type: 'number',
          message: 'Эрэмбэ тоо байх ёстой',
          trigger: 'change'
        }]
      }
    }
  },
  watch: {
    selectedBanner: {
      immediate: true, // Хэрэглэгчийн өгөгдөл анх ороход ажиллана
      handler (newValue) {
        this.formData = {
          ...newValue
        } // Шинэ утгуудыг formData-д хуулбарлах
        this.setDynamicImgUrl(this.selectedBanner.image_path)
      }
    },
    dynamic_img_url () {
      this.formData.image_path = this.dynamic_img_url
    }
  },
  methods: {
    ...mapActions(['setCreateBannerDialog', 'setDynamicImgUrl']),
    handleClose () {
      this.formData = {}
      this.setCreateBannerDialog(false)
    },
    submitForm () {
      this.$refs.formRef.validate((valid) => {
        if (valid) {
          let response
          try {
            if (this.formData.id) {
              response = services.update(this.$route.name, this.formData)
            } else {
              response = services.create(this.$route.name, this.formData)
            }
            if (response) {
              this.handleClose()
              location.reload()
            }
          } catch (error) {
            console.error('API Request Error:', error.message)
            this.$message.error(error.message)
          }
        } else {
          console.log('Алдаа гарлаа, шаардлагатай талбаруудыг бөглөнө үү')
          return false
        }
      })
    }
  }
}

</script>

<style scoped>
  .el-form {
    max-width: 600px;
    margin: 0 auto;
  }

</style>
