<template>
    <el-dialog title="Захиалгийн дэлгэрэнгүй" v-model="seeTrackCodeProgressDialog" width="80%" @close="handleClose">
      <!-- <el-row> -->
        <el-col>
          <div class="txtcenter text_black">
            <h1>{{ this.formData.track_code }}</h1>
          </div><br>
        </el-col>
        <el-col>
          <p class="txtcenter">дээрх track кодтой  захиалга</p><br><br>
        </el-col>
        <!-- {{ formData }} -->
          <!-- {{ user }} -->
        <div display="flex">
          <el-row v-if="formData.Progress && formData.Progress.length">
            <el-col :span="12">
              <el-row>
                <el-col  v-for="progressItem in this.formData.Progress" :key="progressItem.id">
                  <div  class="no-wrap" style="margin-top: 20px;">
                    <el-steps direction="vertical" :active="returnStatus(progressItem)" finish-status="success" :space="50">
                      <el-step :title="progressStatusReturn(progressItem.progress_code)"></el-step>
                    </el-steps>
                  </div>
                  <div style="margin-left: 35px;">
                    <div>Үүсгэсэн огноо: {{ progressItem.createdAt.split('T')[0] }}</div>
                    <div v-if="progressItem.User && progressItem.User.firstName">Бүртэгсэн хэрэглэгч: {{ progressItem.User.firstName }}</div>
                    <div v-if="progressItem.taviur_code">Тавиурын дугаар: {{ progressItem.taviur_code }}</div>
                    <div v-if="progressItem.address_id">Хүргүүлэх хаяг: {{ progressItem.Address.detail_address }}</div>
                    <div v-if="progressItem.startDate">Хадгалуулж эхлэх огноо: {{ progressItem.startDate }}</div>
                    <div v-if="progressItem.startDate">Хадгалуулж дуусах огноо: {{ progressItem.endDate }}</div>
                    <div v-if="progressItem.driver_id">Хүргэлтийн ажилтан: {{ progressItem.Driver.firstName }}</div>
                    <div v-if="progressItem.description">Тайлбар: {{ progressItem.description }}</div>
                    <div v-if="this.formData.urt && this.formData.Progress.progress_code === 130">Ачааны урт:  {{ this.formData.urt }}</div>
                    <div v-if="this.formData.undur && this.formData.Progress.progress_code === 130">Ачааны өндөр: {{ this.formData.undur }}</div>
                    <div v-if="this.formData.urgun && this.formData.Progress.progress_code === 130">Ачааны өргөн: {{ this.formData.urgun }}</div>
                    <div v-if="this.formData.jin && this.formData.Progress.progress_code === 130">Ачааны жин: {{ this.formData.jin }}</div>
                  </div>
                  </el-col><br>
              </el-row>
            </el-col>
            <el-col :span="12">
              <el-row>
                <el-col style="margin-bottom: 10px;">
                  <div v-if="this.formData.price">Төлбөр: {{ $formatCurrency(formData.price) }}</div>
                  <div v-if="this.formData.is_paid === 0">Төлбөр төлсөн эсэх: Төлөөгүй</div>
                  <div v-if="this.formData.is_paid === 1">Төлбөр төлсөн эсэх: Төлсөн</div>
                  <div v-if="this.formData.is_paid === null">Төлбөр төлсөн эсэх: Төлөөгүй</div>
                </el-col>
                <!-- Hurgelt -->
                <el-col v-if="showDelivery">
                  <el-card>
                    <div v-if="this.user.Address && this.user.Address.length > 0">
                    <el-radio-group v-model="this.formData.Progress.address_id">
                      <el-radio v-for="(item, index) in this.user.Address" :key="index" :value="item.id" :label="item.detail_address">
                        {{ item.detail_address }}
                      </el-radio><br><br><br>
                    </el-radio-group><br><br>
                    </div>
                    <div v-else>Хаяг хоосон</div><br><br>
                    <el-button type="primary" @click="createAddressDialogOpen()">Хаяг нэмэх</el-button>
                    <el-button type="success" @click="createProgress(130)" :disabled="addressSaveButtonDisable()">Хадгалах</el-button><br><br>
                  </el-card>
                </el-col>
                <!-- Hadgaluulah -->
                <el-col v-if="showDeposit">
                  <el-card>
                    <div class="block">
                      <span class="demonstration">Эхлэх огноо: </span>
                      <el-date-picker v-model="this.formData.Progress.startDate" type="date" placeholder="Select date and time" :picker-options="pickerOptions"></el-date-picker>
                    </div><br>
                    <div class="block">
                      <span class="demonstration">Дуусах огноо: </span>
                      <el-date-picker v-model="this.formData.Progress.endDate" type="date" placeholder="Select date and time"></el-date-picker><br><br>
                    </div>
                    <el-button type="success" @click="createProgress(150)"> Хадгалах </el-button><br><br>
                  </el-card>
                </el-col>
                <!-- Mongol dah salbar deer irsen -->
                <el-col v-if="showBranch">
                  <el-card>
                    <b>Ачааны үнэ бодох</b><br><br>
                    Ачааны төрөл сонгох
                    <el-select v-model="this.formData.config_type_id" placeholder="Сонгоно уу">
                        <el-option v-for="(item, index) in this.configTypes.ConfigType" :key="index" :value="item.id" :label="item.name" @click="giveValue(item.is_ezelhvvn)"></el-option>
                    </el-select>
                    <div v-if="this.is_ezelhuun === true">
                      Урт: <el-input v-model="this.formData.urt"></el-input><br><br>
                      Өндөр: <el-input v-model="this.formData.undur"></el-input><br><br>
                      Өргөн: <el-input v-model="this.formData.urgun"></el-input><br><br>
                    </div>
                    Жин: <el-input v-model="this.formData.jin"></el-input><br><br>
                    <el-button type="primary" :disabled="priceButtonDisable()" @click="calculatePrice()">Үнэ бодох</el-button><br><br>
                    <span style="color: grey;">Үнэ:</span><el-input disabled="true" v-model="this.formData.price"></el-input><br><br>
                    Тавиурын дугаар:<br> <el-input v-model="this.formData.Progress.taviur_code" ></el-input><br><br>
                    <el-button type="success" @click="createProgress(120)">
                    Хадгалах
                  </el-button>
                  </el-card><br>
                </el-col>
                <!-- Baraa hvleelgen uguh -->
                <el-col v-if="showGiveProduct">
                  <el-card>
                    <h3><b>Төлбөрийн мэдээлэл</b></h3><br>
                    <span>Төлбөр : &nbsp;&nbsp; {{ $formatCurrency(this.formData.price )}} </span><br>
                    Төлбөр төлсөн эсэх : &nbsp;&nbsp;<el-switch inline-prompt style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949" active-text="Төлсөн" inactive-text="Төлөөгүй" v-model="this.formData.is_paid" :active-value="1" :inactive-value="0"></el-switch><br>
                    Төлбөрийн хэлбэр:
                    <el-radio-group v-model="this.formData.paid_type">
                      <el-radio label="cash">Бэлэн мөнгө</el-radio>
                      <el-radio label="transaction">Шилжүүлэг</el-radio>
                      <el-radio label="card">Карт</el-radio>
                    </el-radio-group><br><br>
                    <hr><br>
                    <el-button type="success"  @click="createProgress(200)">Хадгалах</el-button>
                  </el-card><br>
                </el-col>
                <!-- Joloochid huwiarlah -->
                <el-col v-if="drivers">
                  <el-card>
                    <b>Хүргэлтийн ажилтан сонгоно уу</b><br><br>
                    <el-select v-model="this.formData.Progress.driver_id" placeholder="Сонгоно уу">
                      <el-option v-for="item in this.driversList" :key="item.id" :value="item.id" :label="item.firstName"></el-option>
                    </el-select><br><br>
                    <el-button type="success" @click="createProgress(160)">Хадгалах</el-button>
                  </el-card><br>
                </el-col>
                <!-- Achaa salbar deer uldsen -->
                <el-col  v-if="leftBranch">
                  <el-card>
                    Тайлбар:
                    <el-input type="textarea" placeholder="Тайлбар оруулна уу" v-model="this.formData.Progress.description"></el-input><br><br>
                    <el-button type="success" @click="createProgress(170)">Хадгалах</el-button>
                  </el-card>
                </el-col>
                <el-col style="margin-top: 20px;">
                  <el-button class="text_color_white button_width m-b-10" type="primary" @click="showBranch = getBranch()">Монгол салбар хүлээн авсан</el-button><br><br>
                  <el-button class="text_color_white button_width m-b-10" type="info" @click="showDelivery = showDeliveryButton() && getUserAllAddress()">Хүргэлтээр авах</el-button><br><br>
                  <el-button class="text_color_white button_width m-b-10" type="warning" @click="showDeposit = showDepositButton()">Хадгалуулах</el-button><br><br>
                  <el-button class="text_color_white button_width m-b-10" type="success" @click="showGiveProduct = showGiveProductButton()">Бараа хүлээлгэн өгөх</el-button><br><br>
                  <el-button class="text_color_white button_width m-b-10" type="warning" @click="createProgress(140)">Хэрэглэгч салбараас очиж авна</el-button><br><br>
                  <el-button class="text_color_white button_width m-b-10" type="primary" @click="drivers = chooseDriver()">Хүргэлтийн ажилтанд хуваарьлах</el-button><br><br>
                  <el-button class="text_color_white button_width m-b-10" type="info" @click="leftBranch = leftOnBranch()">Ачаа салбар дээр үлдсэн</el-button><br><br>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
          <el-row>
          </el-row>
        </div>
      <!-- </el-row> -->
    </el-dialog>
    <CreateAddressDialog :selectedUser="this.user"/>
  </template>

<script>
import { ElMessage } from 'element-plus'
import { mapActions, mapState } from 'vuex'
import services from '@/services'
import CreateAddressDialog from './createAddressDialog.vue'

export default {
  name: 'seeTrackCodeProgressDialog',
  computed: {
    ...mapState(['seeTrackCodeProgressDialog'])
  },
  components: {
    CreateAddressDialog
  },
  props: {
    selectedTrackCode: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      formData: {},
      user: {},
      showDelivery: false,
      showDeposit: false,
      goToGet: false,
      showBranch: false,
      showGiveProduct: false,
      configTypes: [],
      is_ezelhuun: null,
      driversList: [],
      drivers: false,
      trackCodeDetail: {},
      leftBranch: false
    }
  },
  watch: {
    selectedTrackCode: {
      immediate: true,
      handler (newValue) {
        this.formData = {
          ...newValue
        }
      }
    }
  },
  created () {
    this.getConfigType()
  },
  methods: {
    ...mapActions(['setCreateAddressDialog']),
    returnStatus (element) {
      if (element.progress_code === 100) {
        return 1
      } else if (element.progress_code === 110) {
        return 2
      } else if (element.progress_code === 120) {
        return 3
      } else if (element.progress_code === 130) {
        return 4
      } else if (element.progress_code === 140) {
        return 4
      } else if (element.progress_code === 150) {
        return 5
      } else if (element.progress_code === 160) {
        return 6
      } else if (element.progress_code === 170) {
        return 6
      } else if (element.progress_code === 200) {
        return 7
      }
    },
    ...mapActions(['setSeeTrackCodeProgressDialog']),
    handleClose () {
      this.formData = {}
      this.showDeposit = false
      this.goToGet = false
      this.showBranch = false
      this.showGiveProduct = false
      this.drivers = false
      this.leftBranch = false
      this.setSeeTrackCodeProgressDialog(false)
    },
    async showDeliveryButton () {
      this.showDeposit = false
      this.goToGet = false
      this.showBranch = false
      this.showGiveProduct = false
      this.drivers = false
      this.leftBranch = false
      return true
    },
    async createProgress (data) {
      if (!this.formData.Progress.address_id && data === 130) {
        this.$alert('Уучлаарай. Хүргүүлэх хаяг сонгоно уу.', 'Хоосон', {
          confirmButtonText: 'Ойлголоо'
        })
        return
      } else if (!this.formData.Progress.taviur_code && data === null) {
        this.$alert('Уучлаарай. Тавиурын дугаар оруулна уу.', 'Хоосон', {
          confirmButtonText: 'Ойлголоо'
        })
        return
      } else if (data === 200) {
        this.changePrice()
      }
      try {
        const payload = {
          address_id: this.formData.Progress.address_id,
          start_date: this.formData.Progress.startDate,
          end_date: this.formData.Progress.endDate,
          track_code: this.formData.track_code,
          progress_code: data,
          price: this.formData.price,
          track_code_id: this.formData.id,
          jin: this.formData.jin,
          urt: this.formData.urt,
          undur: this.formData.undur,
          urgun: this.formData.urgun,
          config_type_id: this.formData.config_type_id,
          taviur_code: this.formData.Progress.taviur_code,
          driver_id: this.formData.Progress.driver_id,
          description: this.formData.Progress.description
        }
        const response = await services.update('trackCodes', payload)
        if (response) {
          ElMessage.success('Төлөв амжилттай өөрчлөгдлөө.')
          this.getTrackCodeDetail()
        } else if (!response || response.type !== 'success') {
          ElMessage.error('Өгөгдөл татахад алдаа гарлаа.')
        }
      } catch (error) {
        console.error('Алдаа гарлаа:', error)
        this.$message.error('Өгөгдлийг татахад алдаа гарлаа!')
      }
    },
    async getConfigType () {
      try {
        const response = await services.get('config')
        if (response.type === 'success') {
          this.configTypes = response.data
        } else {
          ElMessage.error('Өгөгдөл татхад алдаа гарлаа.')
        }
      } catch (error) {
        ElMessage.error(error)
      }
    },
    showDepositButton () {
      // this.showDeposit = false
      this.showDelivery = false
      this.goToGet = false
      this.showBranch = false
      this.showGiveProduct = false
      this.drivers = false
      this.leftBranch = false
      return true
    },
    goToGetButton () {
      this.showDeposit = false
      this.showDelivery = false
      // this.goToGet = false
      this.showBranch = false
      this.showGiveProduct = false
      this.drivers = false
      this.leftBranch = false
      return true
    },
    getBranch () {
      this.showDeposit = false
      this.showDelivery = false
      this.goToGet = false
      // this.showBranch = false
      this.showGiveProduct = false
      this.drivers = false
      this.leftBranch = false
      return true
    },
    showGiveProductButton () {
      this.showDeposit = false
      this.showDelivery = false
      this.goToGet = false
      this.showBranch = false
      this.drivers = false
      this.leftBranch = false
      // this.showGiveProduct: false
      return true
    },
    giveValue (data) {
      this.is_ezelhuun = data
    },
    async changePrice () {
      try {
        const payload = { is_paid: this.formData.is_paid, track_code: this.formData.track_code, paid_type: this.formData.paid_type }
        // const trackCode = this.formData.track_code
        const response = await services.update('updateIsPayed', payload)
        if (response.type === 'success') {
          ElMessage.success('Төлбөр амжилттай төлөгдлөө.')
        } else {
          ElMessage.error('Төлбөр шинэчлэхэд алдаа гарлаа.')
        }
      } catch (error) {
        console.error('Error:', error)
        ElMessage.error(`Алдаа гарлаа: ${error.message}`)
      }
    },
    async calculatePrice () {
      if (this.is_ezelhuun === true && this.formData.urt === null) {
        ElMessage.error('Ачааны уртын хэмжээ оруулна уу.')
        return
      } else if (this.is_ezelhuun === true && this.formData.undur === null) {
        ElMessage.error('Ачааны өндрийн хэмжээ оруулна уу.')
        return
      } else if (this.is_ezelhuun === true && this.formData.urgun === null) {
        ElMessage.error('Ачааны өргөний хэмжээ оруулна уу.')
        return
      }
      try {
        const payload = { config_type_id: this.formData.config_type_id, jin: this.formData.jin, urt: this.formData.urt, undur: this.formData.undur, urgun: this.formData.urgun }
        const response = await services.create('calculatePrice', payload)
        if (response.type === 'success') {
          this.formData.price = response.finalPrice
        }
      } catch (error) {
        console.log(error)
      }
    },
    priceButtonDisable () {
      return !this.formData.jin
    },
    async getUserAllAddress () {
      try {
        const phoneNumber = this.formData.phone_number
        const response = await services.get(`userAddress/${phoneNumber}`)
        if (response.type === 'success') {
          this.user = response.data
        }
      } catch (error) {
        console.log(error)
        ElMessage.error(`Алдаа: ${error.message}`)
      }
    },
    addressSaveButtonDisable () {
      return !this.formData.Progress.address_id
    },
    createAddressDialogOpen () {
      this.targetUser = this.user
      this.setCreateAddressDialog(true)
    },
    progressStatusReturn (data) {
      if (data === 100) {
        return 'Хэрэглэгч бүртгэсэн'
      } else if (data === 110) {
        return 'Эрээн дах агуулахаас гарсан'
      } else if (data === 120) {
        return 'Монгол дах салбар хүлээн авсан'
      } else if (data === 130) {
        return 'Хэрэглэгч хүргэлтээр авна'
      } else if (data === 140) {
        return 'Хэрэглэгч салбараас очиж авна'
      } else if (data === 150) {
        return 'Хэрэглэгч салбар дээр хадгалуулна'
      } else if (data === 160) {
        return 'Хүргэлтийн ажилтанд хуваарилсан'
      } else if (data === 170) {
        return 'Ачаа салбар дээр үлдсэн'
      } else if (data === 200) {
        return 'Хэрэглэгч хүлээн авсан'
      }
    },
    async chooseDriver () {
      try {
        const response = await services.get('drivers')
        if (response.type === 'success') {
          this.driversList = response.data
          this.showDeposit = false
          this.showDelivery = false
          this.goToGet = false
          this.showBranch = false
          this.drivers = true
          this.showGiveProduct = false
          this.leftBranch = false
        } else {
          ElMessage.error('Өгөгдөл татхад алдаа гарлаа')
        }
      } catch (error) {
        ElMessage.error(`Алдаа: ${error.message}`)
      }
    },
    async getTrackCodeDetail () {
      try {
        const payload = this.formData.id
        const response = await services.get(`trackCodesId/${payload}`)
        if (response.type === 'success') {
          this.formData = response.data
        } else {
          ElMessage.error('Өгөгдөл татхад алдаа гарлаа')
        }
      } catch (error) {
        ElMessage.error(`Алдаа: ${error.message}`)
      }
    },
    leftOnBranch () {
      this.showDeposit = false
      this.showDelivery = false
      this.goToGet = false
      this.showBranch = false
      this.drivers = false
      this.showGiveProduct = false
      return true
    }
  }
}

</script>

<style scoped>
    .el-form {
      max-width: 600px;
      margin: 0 auto;
    }
    .txtcenter{
    text-align: center !important;
  }
  .text_black {
    color: black;
  }
  .no-wrap {
    white-space: normal;
    display: flex;
  }
  .icon_border{
  width: 20px;
  height: 20px;
  background-color: #409eff;
  color: white;
  border-radius: 5px;
  }
  .text-grey{
    color: grey;
    font-size: 13px;
  }
  .text_color_white {
    color: white;
  }
  .button_width {
    width: 100%;
  }
  .m-b-10 {
    padding-bottom: 10;
  }
  .m_p_sizes {
    margin-bottom: 20px;
    margin-left: 40px;
  }
</style>
