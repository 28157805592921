<template>
  <div>
    <div class="p-8">
      <!-- <div class="lg:hidden mb-4">
        <button @click="toggleSidebar" class="text-gray-800 p-2 border rounded-md">Меню</button>
      </div> -->
      <h2><b>Сайн байна уу? {{ this.user.lastName }}</b></h2>
    </div>
    <el-row justify="center">
      <el-col :xs="24" :sm="8" :md="8" :lg="6" :xl="6">
        <div class="box">
          <div class="icon">
            <font-awesome-icon icon="yen-sign" />
          </div>
          <div class="balance" style="cursor: pointer;">
            <p @click="changeDataOpen('yuan')">{{ active_config.yuan }}₮</p>
          </div>
          <p class="description">Нэг юанийг тооцох төгрөгийн дүн </p>
        </div>
      </el-col>
      <el-col :xs="24" :sm="8" :md="8" :lg="6" :xl="6">
        <div class="box">
          <div class="icon">
            <font-awesome-icon icon="weight-hanging" />
          </div>
          <div class="balance" style="cursor: pointer;">
            <p @click="changeDataOpen('teevriin_huls')">{{ $formatCurrencyComma(active_config.teevriin_huls) }}₮
            </p>
          </div>
          <p class="description">1 митр куб ачаа тээвэрлэх үнэ</p>
        </div>
      </el-col>
    </el-row>
    <el-row class="row-bg" justify="center">
      <el-col :span="22" class="mt-10 w-100">
        <span>Тохиргооны түүх</span>
        <el-table class="mt-5" v-if="data.length > 0" :data="data" :row-class-name="tableRowClassName">
          <el-table-column align="center" label="№" type="index" class="table_style"></el-table-column>
          <el-table-column align="center" label="Юань" prop="yuan">
            <template #default="scope">
              {{ $formatCurrency(scope.row.yuan) }}
            </template>
          </el-table-column>
          <el-table-column align="center" label="Тээврийн хөлс /м3/" prop="teevriin_huls">
            <template #default="scope">
              {{ $formatCurrency(scope.row.teevriin_huls) }}
            </template>
          </el-table-column>
          <el-table-column align="center" label="Огноо">
            <template #default="scope">
              {{ new Date(scope.row.createdAt).toLocaleDateString() }}
            </template>
          </el-table-column>
          <el-table-column align="center" label="Идэвхитэй эсэх">
            <template #default="scope">
              <el-switch @change="changeStatus(scope.row)" v-model="scope.row.is_active" inline-prompt style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949" active-text="Идэвхтэй" inactive-text="Идэвхгүй"></el-switch>
            </template>
          </el-table-column>
          <el-table-column align="center" label="Үйлдэл"
            v-if="checkPermissionsPage('admin')">
            <template v-slot="scope">
              <el-button @click="configDetail(scope.row)" type="primary">Дэлгэрэнүй</el-button>
              <!-- <font-awesome-icon @click="configDetail(scope.row)" icon="plus" style="color: #007BFF; font-size: 30px; margin-right: 10px; cursor: pointer;" /> -->
              <!-- <font-awesome-icon @click="configTypeListDrawer(scope.row)" icon="list" style="color: #007BFF; font-size: 30px; cursor: pointer;" /> -->
            </template>
          </el-table-column>
        </el-table>
        <p v-else>Өгөгдөл байхгүй байна.</p>
        <!-- Pagination -->
         <el-pagination
            @current-change="handlePageChange"
            :current-page="currentPage"
            :page-size="pageSize"
            :total="totalRecords"
            layout="total, prev, pager, next, jumper"
            style="margin-top: 20px; text-align: right;" />
        <el-row>
          <el-col :span="23">
          </el-col>
          <el-col :span="1">
            <el-button v-if="checkPermissionsPage('admin')" @click="createConfigDialogOpen()"
              class="button_style flr">Өгөгдөл нэмэх</el-button>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <!-- <el-drawer v-model="configDetailDrawer" title="Тохиргоотой холбоотой төрлүүд" direction="rtl">
      <div class="config_detail_area">
        <div v-if="selectConfig.ConfigType.length === 0">
          <el-image style="width: 100px; height: 100px" :src="url" fit="fill" /><br>
          <span>Тохиргоонд хамаарах төрөл хоосон байна.</span>
        </div>
        <div v-else>
          <el-card v-for="item,index in selectConfig.ConfigType" :key="index">
            <el-descriptions :title="item.name">
              <el-descriptions-item label="Нэр:">{{ item.name }}</el-descriptions-item>
              <el-descriptions-item label="Тайлбар:">{{ item.description }}</el-descriptions-item>
              <el-descriptions-item label="Эзэлхүүн оролцох:">
                <el-tag size="small">{{ item.is_ezelhvvn }}</el-tag>
              </el-descriptions-item> <br />
              <el-descriptions-item label="Хэмжигдэхүүн үнэ">
                <el-row v-for="element,index in item.ConfigReferences" :key="index">
                  <el-col :span="7">
                    Бага : {{ element.min }}
                  </el-col>
                  <el-col :span="7">
                    Их : {{ element.max }}
                  </el-col>
                  <el-col :span="7">
                    Хэмжээний үнэ : {{ element.price }}
                  </el-col>
                </el-row>
                <el-button class="mt-5" type="info" @click="editConfigType(item)">Засах</el-button>
              </el-descriptions-item>
            </el-descriptions>
          </el-card>
        </div>
      </div>
      <template #footer>
        <div style="flex: auto">
          <el-button class="w-100" type="success" @click="editConfigType()">Үүсгэх</el-button>
        </div>
      </template>
    </el-drawer> -->
    <CreateConfigDialog />
    <CreateConfigDetail :selectConfig="this.selectConfig" :selectTypePass="this.editSelectConfigType" />
    <ChangeDataDialog :selectedConfig="active_config" :transferValue="this.shiljvvlehUtga" />
  </div>
</template>

<script>
import services from '@/services'
import {
  ElMessage
} from 'element-plus'
import {
  mapGetters,
  mapActions
} from 'vuex'
import CreateConfigDialog from './createConfigDialog.vue'
import ChangeDataDialog from './changeDataDialog.vue'
import {
  checkPermissions
} from '@/helpers/custom'
import CreateConfigDetail from './createConfigDetail.vue'
import urlempty from '@/assets/empty.png'
export default {
  name: 'DashboardPage',
  computed: {
    ...mapGetters([
      'user'
    ])
  },
  components: {
    CreateConfigDialog,
    CreateConfigDetail,
    ChangeDataDialog
  },
  data () {
    return {
      totalRecords: 0,
      data: [{
        yuan: null,
        teevriin_huls: null,
        // minimum_hemjee: null,
        // minimum_amount: null,
        // unit_price: null,
        // createdAt: null,
        ConfigReferences: {
          min: null,
          max: null,
          price: null
        }
      }],
      // configDetailDrawer: false,
      selectConfig: {},
      url: urlempty,
      currentPage: 1,
      pageSize: 10,
      // targetConfig: {},
      // editSelectConfigType: {
      //   id: null,
      //   name: null,
      //   description: null,
      //   is_ezelhvvn: null,
      //   config_id: null,
      //   configReferences: []
      // },
      shiljvvlehUtga: null,
      active_config: {}
    }
  },
  created () {
    this.loadData()
    this.getActiveConfig()
  },
  methods: {
    ...mapActions(['setCreateConfigDialog', 'setCreateYuanDialog',
      'setChangeHereglegchTootsohDunDialog', 'setChangeDoodJinTootsohDunDialog',
      'setChangeTeeveriinUneTootsoolohDoodJinDialog', 'setCreateConfigDetailDialog',
      'setChangeMitrKubAchaaTeeverlehUneDialog', 'setChangeDataDialog'
    ]),
    tableRowClassName ({
      row,
      rowIndex
    }) {
      if (rowIndex === 1) {
        return 'success-row'
      }
      return ''
    },
    checkPermissionsPage (data) {
      return checkPermissions(data)
    },
    handlePageChange (page) {
      this.currentPage = page // Одоогийн хуудсыг шинэчилнэ
      this.loadData() // Шинэ хуудсын өгөгдлийг татна
    },
    async loadData () {
      try {
        const response = await services.get('configs', {
          params: {
            page: this.currentPage,
            limit: this.pageSize
          }
        })
        if (response) {
          this.data = response.data
          this.totalRecords = response.total
          this.totalPages = response.totalPages
        } else {
          ElMessage.warning('Өгөгдөл олдсонгүй!')
        }
      } catch (error) {
        console.error('Error fetching data: ', error)
        ElMessage.error(`Өгөгдөл ачаалахад алдаа гарлаа: ${error.message}`)
      }
    },
    // viewDetails (row) {
    //   console.log('Дэлгэрэнгүй мэдээлэл:', row)
    //   ElMessage.info('Дэлгэрэнгүй мэдээлэл харагдаж байна!')
    // },
    // editConfigType (data) {
    //   this.editSelectConfigType = data
    //   this.setCreateConfigDetailDialog(true)
    // },
    configDetail (data) {
      this.selectConfig = data
      this.setCreateConfigDetailDialog(true)
    },
    // configTypeListDrawer (data) {
    //   this.configDetailDrawer = true
    //   this.selectConfig = data
    // },
    // handleCloseConfigTypeDrawer () {
    //   this.configDetailDrawer = false
    // },
    createConfigDialogOpen () {
      this.setCreateConfigDialog(true)
    },
    // createYuanDialogOpen () {
    //   this.setCreateYuanDialog(true)
    // },
    // changeMitrKubAchaaTeeverlehUneDialogOpen () {
    //   this.setChangeMitrKubAchaaTeeverlehUneDialog(true)
    // },
    // changeTeeveriinUneTootsoolohDoodJinDialogOpen () {
    //   this.setChangeTeeveriinUneTootsoolohDoodJinDialog(true)
    // },
    changeDataOpen (data) {
      this.shiljvvlehUtga = data
      this.setChangeDataDialog(true)
    },
    changeDoodJinTootsohDunDialogOpen () {
      this.setChangeDoodJinTootsohDunDialog(true)
    },
    changeHereglegchTootsohDunDialogOpen () {
      this.setChangeHereglegchTootsohDunDialog(true)
    },
    updateStatusData () {
      if (this.data.length > 0) {
        const firstItem = this.data[0] // Эхний элемент байгааг шалгана
        this.statsData = [{
          value: firstItem.yuan || 0
        },
        {
          value: firstItem.teevriin_huls || 0
        },
        {
          value: firstItem.minimum_hemjee || 0
        },
        {
          value: firstItem.minimum_amount || 0
        }
        ]
      } else {
        console.warn('Хоосон өгөгдөл байна, статистик шинэчлэгдэхгүй.')
        this.statsData = [{
          value: 0
        },
        {
          value: 0
        },
        {
          value: 0
        },
        {
          value: 0
        }
        ]
      }
    },
    async changeStatus (data) {
      try {
        const response = await services.update('configsYuan', data)
        if (response && response.data) {
          ElMessage.success('Төлөв амжилттай өөрчлөгдлөө!')
        }
      } catch (error) {
        console.error('Aldaa: ', error.message)
        this.$message.error(error.message)
      }
    },
    async getActiveConfig () {
      try {
        const response = await services.get('config')
        if (response) {
          this.active_config = response.data
        }
      } catch (error) {
        console.error('Aldaa: ', error.message)
        this.$message.error(error.message)
      }
    }
  }
}

</script>

<style scoped>
  .el-table .warning-row {
    --el-table-tr-bg-color: var(--el-color-warning-light-9);
  }

  .el-table tr {
    --el-table-tr-bg-color: green;
  }

  .no-wrap {
    white-space: normal;
    display: flex;
  }

  .box {
    background-color: #f0f7fa;
    border-radius: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    padding: 20px;
    width: 200px;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* position: relative; */
  }

  .icon {
    /* display: flex; */
    /* justify-content: space-between; */
    align-items: center;
    font-size: 30px;
  }

  .font_family {
    font-family: 'Arial', sans-serif
  }

  .menu-icon {
    font-size: 20px;
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .balance p {
    font-size: 28px;
    font-weight: bold;
    margin: 10px 0;
  }

  .description {
    color: #555;
    font-size: 14px;
    margin-top: 5px;
  }

  .custom-icon {
    font-size: 30px;
    font-weight: bold;
    color: #000;
  }

  .table_style {
    background-color: #f0f7fa;
  }

  .button_style {
    background-color: #212936;
    color: white;
    /* width: 200px; */
    /* height: 50px; */
    /* font-size: 20px; */
  }

  .menu-icon {
    font-size: 16px;
    color: #000;
  }

  .cusor {
    cursor: pointer;
  }

  .config_detail_area {
    text-align: center;
  }

  .config_detail_area span {
    color: gray;
  }

  .config_name {
    font-size: 18px;
    color: blue;
    font-weight: bold;
  }

</style>
