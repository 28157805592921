<template>
    <el-dialog title="Config үүсгэх" v-model="createConfigDialog" width="50%"
      @close="handleClose">
      <div>
        <el-form :model="formData" :rules="rules" ref="formRef" label-width="120px">
          <el-form-item label="Юанийн ханш" prop="yuan">
            <el-input type="number" v-model="formData.yuan" placeholder="Нэг юанийг тооцох төгрөгийн дүн оруулна уу /₮/" min="0"></el-input>
          </el-form-item>
          <el-form-item label="Тээврийн хөлс"  prop="teevriin_huls">
            <el-input type="number" v-model="formData.teevriin_huls" placeholder="1 митр куб ачаа тээвэрлэх үнэ оруулна уу /₮/" min="0"></el-input>
          </el-form-item>
          <el-form-item label="Идэвхитэй эсэх">
            <el-switch v-model="formData.is_active"></el-switch>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm">Хадгалах</el-button>
            <el-button @click="resetForm">Цэвэрлэх</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </template>

<script>
// import router from '@/router'
import services from '../services'

import {
  ElForm,
  ElFormItem,
  ElInput,
  ElButton
} from 'element-plus'
import {
  mapActions,
  mapState
} from 'vuex'

export default {
  name: 'FAQsDialog',

  components: {
    ElForm,
    ElFormItem,
    ElInput,
    ElButton
  },
  computed: {
    ...mapState(['createConfigDialog'])
  },
  created () {

  },
  data () {
    return {
      formData:
        {
          yuan: null,
          teevriin_huls: null,
          minimum_hemjee: null,
          minimum_amount: null,
          unit_price: null,
          createdAt: null,
          is_active: true
        },
      rules: {
        yuan: [{
          required: true,
          message: 'Юанийн ханш оруулна уу',
          trigger: 'blur'
        }],
        teevriin_huls: [{
          required: true,
          message: 'Тээврийн хөлс оруулна уу',
          trigger: 'blur'
        }]
      }
    }
  },
  methods: {
    ...mapActions(['setCreateConfigDialog']),
    handleClose () {
      this.formData = {}
      this.setCreateConfigDialog(false)
    },
    submitForm () {
      this.$refs.formRef.validate(async (valid) => {
        if (valid) {
          if (this.formData.yuan < 0) {
            this.$alert('Юанийн ханш хасах дүнтэй байж болохгү.', 'Анхаар', {
              confirmButtonText: 'Ойлголоо'
            })
            return
          } else if (this.formData.teevriin_huls < 0) {
            this.$alert('Тээврийн хөлс хасах дүнтэй байж болохгү.', 'Анхаар', {
              confirmButtonText: 'Ойлголоо'
            })
            return
          }
          try {
            let response
            if (this.formData.id) {
              response = await services.update(this.$route.name, this.formData)
            } else {
              response = await services.create(this.$route.name, this.formData)
            }
            // minii_yum_lham
            if (response.type === 'success') {
              this.$alert('Бүртгэл амжилттай хийгдлээ.', 'Амжилттай', {
                confirmButtonText: 'Ойлголоо',
                callback: () => {
                  this.handleClose()
                  location.reload()
                }
              })
            }
          } catch (error) {
            if (error.response && error.response.status) {
              this.$alert('Уучлаарай: ' + error, 'Алдаа', {
                confirmButtonText: 'Ойлголоо'
              })
            }
            console.error('API Request Error:', error.message)
            this.$message.error(error.message)
          }
        } else {
          console.log('Алдаа гарлаа, шаардлагатай талбаруудыг бөглөнө үү')
          return false
        }
      })
    }

  }
}

</script>

  <style scoped>
    .el-form {
      max-width: 600px;
      margin: 0 auto;
    }

  </style>
